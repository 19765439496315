import { useContext, useEffect, useState } from "react";
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  Switch,
  Typography,
} from "@mui/material";
import ProjectTable, { SectionType } from "../ProjectTable";
import AppStateContext from "../../../contexts/AppStateContext";
import { ProductionVMType, ProductionsSummaryType } from "types/ProductionType";
import useStateWithLocalStorage from "hooks/UseStateWithLocalStorage";
import InitialsCircle from "components/InitalsCircle";
import { MachineType } from "types/MachineType";
import { apiGetMachines } from "util/network/Machine";
import SearchField from "components/SearchField";
import useStateWithSessionStorage from "hooks/UseStateWithSessionStorage";
import StatusBox from "components/Dashboard/StatusBox";
import {
  apiGetProduction,
  apiGetProductionsSummary,
} from "util/network/Productions";
import ProductionTasks from "components/ProductionTasks/ProductionTasks";
import Modal from "components/Modals/Modal";
import AttemptCloseModal from "components/ProductionTasks/AttemptCloseModal";

const styles = {
  inputField: {
    backgroundColor: "#fff",
    border: "1px solid #000",
    color: "#000",
    transition: "color 0.2s, border-color 0.2s",
    "&:hover": {
      borderColor: "#fff",
      color: "#fff !important",
    },
  },
  inputTitle: {
    "&:hover": {
      color: "#fff !important",
    },
  },
};

const ProjectManagementUI = () => {
  const { token, employees } = useContext(AppStateContext);
  const [production, setProduction] = useState<ProductionVMType>();
  const [newProdModal, setNewProdModal] = useState(false);
  const [searchValue, setSearchValue] = useStateWithSessionStorage<string>(
    "",
    "productionSearchVal"
  );
  const [refreshFunc, setRefreshFunc] = useState<() => void>(() => () => {});

  const [tempSection, setTempSection] = useState<SectionType>({
    all: false,
    notStart: false,
    review: false,
    cam: false,
    production: false,
    postprocess: false,
    shipping: false,
    done: false,
    outsourced: false,
    custom: false,
  });

  const [section, setSection] = useStateWithLocalStorage<SectionType>(
    {
      all: false,
      notStart: false,
      review: false,
      cam: false,
      production: false,
      postprocess: false,
      shipping: false,
      done: false,
      outsourced: false,
      custom: false,
    },
    "productionFilter"
  );

  useEffect(() => {
    setTempSection((old) => {
      return {
        ...old,
        ...section,
      };
    });
  }, [section]);

  const [selectedUsers, setSelectedUsers] = useStateWithLocalStorage<number[]>(
    [],
    "productionUsers"
  );
  const [selectedMachines, setSelectedMachines] = useStateWithLocalStorage<
    number[]
  >([], "productionMachines");
  const [machines, setMachines] = useState<MachineType[]>([]);
  const [productionSummary, setProductionSummary] =
    useState<ProductionsSummaryType>();
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);

  useEffect(() => {
    if (production?.productId) {
      apiGetProduction(token, production?.productId).then((production) => {
        setProduction(production);
      });
    }
  }, [token, production?.productId]);

  useEffect(() => {
    apiGetMachines(token).then((res) => {
      setMachines(res);
    });
  }, [token]);

  const handleSectionChange = (event: any) => {
    // If 'all' is checked we make sure to uncheck everything else.
    // We should also uncheck all if anything else is checked.
    // Lastly if we uncheck the last box that is not 'all' then we should check 'all'
    if (event.target.name === "all") {
      if (event.target.checked) {
        setTempSection((prev) => ({
          ...prev,
          all: true,
          notStart: false,
          review: false,
          cam: false,
          production: false,
          postprocess: false,
          shipping: false,
          custom: false,
        }));
      } else {
        setTempSection((prev) => ({
          ...prev,
          all: false,
        }));
      }
    } else {
      if (event.target.checked) {
        setTempSection({
          ...tempSection,
          all: !["outsourced", "done", "all"].includes(event.target.name)
            ? false
            : true,
          [event.target.name]: true,
        });
      } else {
        setTempSection({
          ...tempSection,
          [event.target.name]: false,
        });
      }
    }
  };

  useEffect(() => {
    // Periodically check every minute for new quotes
    if (token) {
      apiGetProductionsSummary(token).then((res) => {
        setProductionSummary(res);
      });
    }
    const interval = setInterval(() => {
      if (token) {
        apiGetProductionsSummary(token).then((res) => {
          setProductionSummary(res);
        });
      }
    }, 900000);
    return () => clearInterval(interval);
  }, [token]);

  return (
    <Grid container>
      <Grid item xs={12}>
        <div
          style={{
            // minWidth: 120,
            paddingTop: "20px",
            display: "flex",
            justifyContent: "space-between",
            background: "var(--header)",
            color: "#fff",
            height: "6vh",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              paddingRight: "10px",
            }}
          >
            <Typography
              fontWeight={"bold"}
              variant={"h6"}
              style={{
                textTransform: "uppercase",
                color: "#fff",
                paddingLeft: "1rem",
              }}
            >
              &nbsp;Production - overview &nbsp;
            </Typography>
          </div>
          <div
            style={{
              display: "flex",
              paddingRight: "2rem",
              justifyContent: "center",
            }}
          >
            <div style={{ marginRight: "1em" }}>
              <Button
                onClick={() => setNewProdModal(true)}
                variant="contained"
                sx={{
                  borderRadius: 0,
                  maxHeight: 40,
                  width: "10rem",
                  background: "#E0A900",
                  lineHeight: 2,
                  color: "#fff",
                  "&:hover": {
                    color: "#E0A900",
                    border: "1px solid #E0A900",
                    background: "transparent",
                  },
                }}
              >
                + Production
              </Button>
              {showConfirmDialog && (
                <AttemptCloseModal
                  isOpen={showConfirmDialog}
                  onClose={() => setShowConfirmDialog(false)}
                  onConfirm={() => setNewProdModal(false)}
                />
              )}
              {newProdModal && (
                <Modal
                  isOpen={newProdModal}
                  onClose={() => {
                    setShowConfirmDialog(true);
                  }}
                  productionTasks
                >
                  <ProductionTasks
                    onClose={() => setNewProdModal(false)}
                    isOpen={newProdModal}
                    onSave={() => {
                      setNewProdModal(false);
                    }}
                  />
                </Modal>
              )}
            </div>
            <div style={{ marginRight: "1em" }}>
              <SearchField onChange={setSearchValue} value={searchValue} />
            </div>
            <FormControl
              style={{ width: "10vw", marginRight: "1em" }}
              sx={{ color: "#fff" }}
              variant="filled"
              size="small"
            >
              <InputLabel
                id="demo-simple-select-filled-label"
                // sx={{ color: "#000", fontStyle: "bold" }}
                sx={styles.inputTitle}
              >
                User
              </InputLabel>
              <Select
                style={styles.inputField}
                labelId="demo-simple-select-standard-label"
                id="demo-simple-select-filled"
                value={selectedUsers}
                label="User"
                onChange={(event) => {
                  setSelectedUsers(event.target.value as number[]);
                }}
                multiple
                renderValue={(selected) => (
                  <div style={{ display: "flex" }}>
                    {employees.items
                      ?.filter((e) => selected.indexOf(e.id) > -1)
                      .map((e, index) => {
                        return (
                          <InitialsCircle
                            initials={`${e.email
                              ?.charAt(0)
                              .toUpperCase()}${e.email
                              ?.charAt(1)
                              .toUpperCase()}${e.email
                              ?.charAt(2)
                              .toUpperCase()}`}
                          />
                        );
                      })}
                  </div>
                )}
                sx={{ background: "#fff", color: "#fff" }}
              >
                {employees.items
                  ?.filter(
                    (item) => item.firstName?.toLowerCase() !== "machine"
                  )
                  .map((employee) => {
                    return (
                      <MenuItem key={employee.id} value={employee.id}>
                        <Checkbox
                          checked={selectedUsers.indexOf(employee.id) > -1}
                        />
                        <ListItemText
                          primary={`${employee.firstName} ${employee.lastName}`}
                        />
                      </MenuItem>
                    );
                  })}
              </Select>
            </FormControl>
            <FormControl
              style={{ width: "10vw", marginRight: "1em" }}
              size="small"
              variant="filled"
            >
              <InputLabel
                id="demo-simple-select-filled-label"
                sx={styles.inputTitle}
              >
                Machine
              </InputLabel>
              <Select
                style={styles.inputField}
                labelId="demo-simple-select-filled-label"
                id="demo-simple-select-filled"
                value={selectedMachines}
                label="Machine"
                onChange={(event) => {
                  setSelectedMachines(event.target.value as number[]);
                }}
                multiple
                renderValue={(selected) => (
                  <div style={{ display: "flex" }}>
                    {machines
                      ?.filter((e) => selected.indexOf(e.id) > -1)
                      .map((e, index) => {
                        return <InitialsCircle initials={e.label} />;
                      })}
                  </div>
                )}
                sx={{ background: "#fff" }}
              >
                {machines?.map((machine) => {
                  return (
                    <MenuItem key={machine.id} value={machine.id}>
                      <Checkbox
                        checked={selectedMachines.indexOf(machine.id) > -1}
                      />
                      <ListItemText primary={`${machine.name}`} />
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </div>
        </div>
      </Grid>
      <Grid
        item
        xs={12}
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          padding: "1rem 1.5rem 0 1.5rem",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <div style={{ display: "flex", flexDirection: "row" }}>
            <StatusBox
              title={"All"}
              amount={productionSummary?.totalActive}
              smallText="Tasks"
              checked={tempSection.all}
              onChange={handleSectionChange}
              name={"all"}
            />
            <StatusBox
              title={"Review"}
              amount={productionSummary?.reviewReady}
              smallText="Tasks Ready"
              checked={tempSection.review}
              onChange={handleSectionChange}
              name={"review"}
            />
            <StatusBox
              title={"CAM"}
              amount={productionSummary?.camReady}
              smallText="Tasks Ready"
              checked={tempSection.cam}
              onChange={handleSectionChange}
              name={"cam"}
            />
            <StatusBox
              title={"Production"}
              amount={productionSummary?.productionReady}
              smallText="Tasks Ready"
              checked={tempSection.production}
              onChange={handleSectionChange}
              name={"production"}
            />
            <StatusBox
              title={"Afterwork"}
              amount={productionSummary?.afterworkReady}
              smallText="Tasks Ready"
              checked={tempSection.postprocess}
              onChange={handleSectionChange}
              name={"postprocess"}
            />
            <StatusBox
              title={"Ship"}
              amount={productionSummary?.shippingReady}
              smallText="Tasks Ready"
              checked={tempSection.shipping}
              onChange={handleSectionChange}
              name={"shipping"}
            />
            {/* <StatusBox
              title={"Custom"}
              amount={productionSummary?.customReady}
              smallText="Tasks Ready"
              checked={tempSection.custom}
              onChange={handleSectionChange}
              name={"Custom"}
            /> */}
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              paddingLeft: "6rem",
            }}
          >
            <StatusBox
              title={"M3"}
              machineTasks={`${
                productionSummary?.machineInfo["3"]?.items || 0
              } | ${productionSummary?.machineInfo["3"]?.time.toFixed(1) || 0}`}
              smallText="tasks | machine time"
            />
            <StatusBox
              title={"M4"}
              machineTasks={`${
                productionSummary?.machineInfo["4"]?.items || 0
              } | ${productionSummary?.machineInfo["4"]?.time.toFixed(1) || 0}`}
              smallText="tasks | machine time"
            />
          </div>
        </div>
      </Grid>
      <Grid item xs={12}>
        {" "}
        <div
          style={{ paddingLeft: "1.5rem", paddingTop: "0", paddingBottom: "0" }}
        >
          <FormControlLabel
            label="Done"
            control={
              <Checkbox
                checked={tempSection.done}
                onChange={handleSectionChange}
                name={"done"}
              />
            }
          />
          <FormControlLabel
            label="Outsourced"
            control={
              <Switch
                checked={tempSection.outsourced}
                onChange={handleSectionChange}
                name={"outsourced"}
              />
            }
          />
          <button
            onClick={() => {
              setSection({
                ...tempSection,
              });
            }}
            style={{
              padding: "8px 16px",
              background: "var(--mainYellow)",
              color: "white",
              border: "none",
              borderRadius: "4px",
              fontSize: "16px",
              cursor: "pointer",
              transition: "background-color 0.3s",
            }}
          >
            Apply
          </button>
        </div>
      </Grid>

      <Grid item xs={12} sx={{ padding: "1.5rem" }}>
        <ProjectTable
          selectedUsers={selectedUsers}
          selectedMachines={selectedMachines}
          section={section}
          machinesIn={machines}
          searchValue={searchValue}
          setRefreshFunc={setRefreshFunc}
        />
      </Grid>
    </Grid>
  );
};

export default ProjectManagementUI;
