import useStateWithLocalStorage from "hooks/UseStateWithLocalStorage";
import {
  createContext,
  FunctionComponent,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { UserListType, UserType } from "types/users/UserTypes";
import { apiGetEmployees, apiGetMe } from "util/network/Users";
import { LanguageFileType } from "../types/Common";
import da_dk from "../util/LanguageUtil/da_dk";

type AppStateContextType = {
  token: string;
  setToken: Function;
  user?: UserType;
  employees: UserListType;
  refreshEmployees: Function;
  strings: LanguageFileType;
  showUploadModal: boolean;
  setShowUploadModal: Function;
  showUserModal: boolean;
  setShowUserModal: Function;

  showLoadingModal: boolean;
  setShowLoadingModal: Function;
  showThreeDModal: boolean;
  setShowThreeDModal: Function;
  productId: number;
  setProductId: Function;
  setShowLoginModal: Function;
  showLoginModal: boolean;
  setShowResetPassword: Function;
  showResetPassword: boolean;

  setShowLogoutModal: Function;
  showLogoutModal: boolean;
  showCalculatorModal: boolean;
  setShowCalculatorModal: Function;
  logUserOut: Function;
  checkUserValid: Function;
  showChat: boolean;
  setShowChat: Function;
  showInternalChat: boolean;
  setShowInternalChat: Function;
};

const contextDefaultValues: AppStateContextType = {
  token: "",
  setToken: () => {},
  user: undefined,
  employees: { items: [], page: 0, pageSize: 0, total: 0 },
  refreshEmployees: () => Promise.resolve(),
  strings: da_dk,
  showUploadModal: false,
  setShowUploadModal: () => {},
  showUserModal: false,
  setShowUserModal: () => {},

  showResetPassword: false,
  setShowResetPassword: () => {},
  showLoadingModal: false,
  setShowLoadingModal: () => {},
  showThreeDModal: false,
  setShowThreeDModal: () => {},
  productId: 0,
  setProductId: () => {},
  showLoginModal: false,
  setShowLoginModal: () => {},

  showLogoutModal: false,
  setShowLogoutModal: () => {},
  setShowCalculatorModal: () => {},
  showCalculatorModal: false,
  logUserOut: () => {},
  checkUserValid: () => {},

  setShowChat: () => {},
  showChat: false,
  setShowInternalChat: () => {},
  showInternalChat: false,
};

const AppStateContext =
  createContext<AppStateContextType>(contextDefaultValues);

export const AppStateProvider: FunctionComponent<{
  children: React.ReactNode;
}> = ({ children }) => {
  const [token, setToken] = useStateWithLocalStorage<string>(
    localStorage.getItem("token") ?? "",
    "token"
  );
  const [user, setUser] = useState<UserType>();
  const [language, setLanguage] = useState("dk");
  const [employees, setEmployees] = useState<UserListType>({
    items: [],
    page: 0,
    pageSize: 0,
    total: 0,
  });
  const [showUploadModal, setShowUploadModal] = useState<boolean>(false);
  const [showUserModal, setShowUserModal] = useState<boolean>(false);
  const [showLoadingModal, setShowLoadingModal] = useState<boolean>(false);
  const [showThreeDModal, setShowThreeDModal] = useState<boolean>(false);
  const [productId, setProductId] = useState<number>(0);
  const [showLoginModal, setShowLoginModal] = useState<boolean>(false);
  const [showResetPassword, setShowResetPassword] = useState<boolean>(false);
  const [showLogoutModal, setShowLogoutModal] = useState<boolean>(false);
  const [showChat, setShowChat] = useState<boolean>(false);
  const [showInternalChat, setShowInternalChat] = useState<boolean>(false);
  const [showCalculatorModal, setShowCalculatorModal] =
    useState<boolean>(false);
  const strings = useMemo(() => {
    return da_dk;
  }, [language]);

  const logUserOut = () => {
    window.location.replace("/login");
    sessionStorage.clear();
    localStorage.removeItem("token");
    setToken("");
  };

  const refreshEmployees = () => {
    if (token) {
      return apiGetEmployees(token, 1, 10000).then((res) => {
        setEmployees(res);
      });
    }
    return Promise.resolve();
  };

  const checkUserValid = useCallback(() => {
    if (token === "") return;
    apiGetMe(token)
      .then((res) => {
        if (user?.id !== res.id) {
          setUser(res);
        }
        // console.log(res);
      })
      .catch((err) => {
        // console.log(err);
        logUserOut();
      });
  }, [token, user]);

  useEffect(() => {
    if (token) {
      refreshEmployees();
    }
  }, [token]);

  useEffect(() => {
    if (token) {
      checkUserValid();
    }
  });

  return (
    <AppStateContext.Provider
      value={{
        token,
        setToken,
        user,
        employees,
        refreshEmployees,
        strings,
        showUploadModal,
        setShowUploadModal,
        showUserModal,
        setShowUserModal,
        showLoadingModal,
        setShowLoadingModal,
        showThreeDModal,
        setShowThreeDModal,
        productId,
        setProductId,
        showLoginModal,
        setShowLoginModal,
        showResetPassword,
        setShowResetPassword,
        showLogoutModal,
        setShowLogoutModal,
        showCalculatorModal,
        setShowCalculatorModal,
        logUserOut,
        checkUserValid,
        showChat,
        setShowChat,
        showInternalChat,
        setShowInternalChat,
      }}
    >
      {children}
    </AppStateContext.Provider>
  );
};
export default AppStateContext;
