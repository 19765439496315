// Author: Erik s195397
import {
  FunctionComponent,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import AppStateContext from "../../contexts/AppStateContext";
import PreviewButton from "components/Generic/PreviewButton";
import { OrderItemType } from "types/OrderTypes";
import { apiGetProduct, apiUpdateProduct } from "util/network/Products";
import { Button, Checkbox, Menu, MenuItem } from "@mui/material";
import { CreateRequisitionItemType } from "types/RequisitionTypes";
import {
  CNCProductDetailsType,
  Print3DProductDetailsType,
  ProductTypeEnum,
  SheetProductDetailsType,
} from "types/products/ProductCommandsType";
import { ArrowDropDown, PictureAsPdf } from "@mui/icons-material";
import { fetchPDFFile } from "util/FileDownload";
import { API_URL } from "util/network/common";
import { CartItemType } from "types/CartTypes";

type PropsType = {
  selected: boolean;
  project: OrderItemType | CartItemType;
  changeSelected: Function;
  changeItemPrice: Function;
  priceIn: number | undefined;
  disabled?: boolean;
  type: "order" | "cart";
};

// Requisition Order Item Row
const ReqOrderItemRow: FunctionComponent<PropsType> = ({
  project,
  changeSelected,
  changeItemPrice,
  selected,
  priceIn,
  disabled,
  type,
}) => {
  const { token } = useContext(AppStateContext);
  const [details, setDetails] = useState<
    CNCProductDetailsType | Print3DProductDetailsType | SheetProductDetailsType
  >();
  const [priceText, setPriceText] = useState<string>(`${priceIn ?? ""}`);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [pdfState, setPdfState] = useState<boolean>(false);
  const [pdfName, setPdfName] = useState<any>("");
  const hiddenFileInput = useRef<any>(null);

  const handlePdfClick = () => {
    hiddenFileInput.current?.click();
  };

  //Inital load
  useEffect(() => {
    if (project.product.id) {
      apiGetProduct(token, project.product.id).then((res) => {
        if (
          res.productType === ProductTypeEnum.CNC ||
          res.productType === ProductTypeEnum.PRINT3D ||
          res.productType === ProductTypeEnum.SHEET
        ) {
          setDetails(res.details as any);
          if (
            res.productType === ProductTypeEnum.CNC ||
            res.productType === ProductTypeEnum.SHEET
          ) {
            setPdfName((res.details as CNCProductDetailsType)?.blueprint ?? "");
            setPdfState(
              (res.details as CNCProductDetailsType)?.blueprint != null
            );
          }
        }
      });
    }
  }, []);

  // Update price
  useEffect(() => {
    console.log(project.product.id, priceIn);
    handlePrice(priceIn?.toFixed(2) ?? "");
  }, [priceIn]);

  // Update selected
  const handleSelect = () => {
    const price = parseFloat(priceText);
    const reqItem: CreateRequisitionItemType = {
      productId: project.product.id,
      price: price,
    };
    if (type === "order") {
      reqItem.orderItemId = project.id;
    } else {
      reqItem.quoteItemId = project.id;
    }
    changeSelected(reqItem);
  };

  const handleSubmit = async (files: FileList) => {
    const file = files[0];
    //states here
    const data = new FormData();
    data.append("file", file);
    data.append("filename", file.name);
    let url = `${API_URL}/v1/ecommerce/products/${details?.productId}/blueprint`;

    if (file.type === "application/pdf" && details) {
      const pdfResponse = await fetch(url, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: data,
      });
      const responsePDF = await pdfResponse.text();
      if (pdfResponse.status == 201) {
        setPdfName(responsePDF);
        const command = {
          quantity: details.quantity,
          threads: details.threads,
          comment: details.comment,
          blueprint: responsePDF,
        };
        apiUpdateProduct(token, details?.productId, command).then((res) => {
          setDetails(res.details as any);
        });
        setPdfState(!pdfState);
      } else console.log("Fail");
    }
  };

  const deletePDF = async () => {
    if (!details) return;
    let url = `${API_URL}/v1/ecommerce/products/${details?.productId}/blueprint`;
    const pdfDelete = await fetch(url, {
      method: "DELETE",
      headers: {
        Authorization: "Bearer " + token,
      },
    });

    if (pdfDelete.status == 200 && details) {
      setPdfState(false);
      const command = {
        quantity: details.quantity,
        threads: details.threads,
        comment: details.comment,
        blueprint: null,
      };
      apiUpdateProduct(token, details?.productId, command).then((res) => {
        setDetails(res.details as any);
      });
      setPdfName("");
    }
  };

  // Update price
  const handlePrice = (price: string) => {
    // Relace comma with dot
    if (price === "") {
      setPriceText("");
      return;
    }
    price = price.replace(",", ".");
    let priceFloat = parseFloat(price);
    if (isNaN(priceFloat)) {
      priceFloat = 0;
    }
    setPriceText(`${priceFloat}`);
    const reqItem: CreateRequisitionItemType = {
      productId: project.product.id,
      price: priceFloat,
    };
    if (type === "order") {
      reqItem.orderItemId = project.id;
    } else {
      reqItem.quoteItemId = project.id;
    }
    if (selected) {
      changeItemPrice(reqItem);
    }
  };
  return (
    <TableRow
      hover
      key={details && details.modelId}
      selected={selected && !disabled}
      onClick={() => {
        if (!disabled) {
          handleSelect();
        }
      }}
    >
      {disabled ? null : (
        <TableCell padding="checkbox" colSpan={2}>
          <Checkbox color="primary" checked={selected} />
        </TableCell>
      )}
      <TableCell>
        <div style={{ display: "flex", width: "6em" }}>
          <div>
            <b>#{project.product.id}</b>
            <p style={{ margin: 0 }}>{project.product.productType}</p>
          </div>
          {details ? (
            <PreviewButton
              productId={project.product.id}
              modelId={details.modelId}
              size={"small"}
            />
          ) : (
            ""
          )}
          {(project.product.productType === ProductTypeEnum.CNC ||
            project.product.productType == ProductTypeEnum.SHEET) && (
            <>
              <input
                type="file"
                accept="application/pdf"
                ref={hiddenFileInput}
                onChange={(e) => e.target.files && handleSubmit(e.target.files)}
                style={{ display: "none" }}
                multiple={false}
                onClick={(event: any) => {
                  event.stopPropagation();
                  event.target.value = null;
                }}
              />
              <Button
                endIcon={
                  <ArrowDropDown color={pdfState ? "info" : "primary"} />
                }
                onClick={(e: any) => {
                  e.stopPropagation();
                  setAnchorEl(e.currentTarget);
                }}
                style={{ fontWeight: "bold" }}
                disabled={disabled}
              >
                <PictureAsPdf color={pdfState ? "info" : "primary"} />
              </Button>

              <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={() => {
                  setAnchorEl(null);
                }}
                onClick={(event) => {
                  event.stopPropagation();
                }}
              >
                {pdfState ? (
                  <>
                    <MenuItem
                      value={"download_pdf"}
                      onClick={(event) => {
                        setAnchorEl(null);
                        if (!details) return;
                        fetchPDFFile(
                          token,
                          (details as CNCProductDetailsType)?.blueprint ??
                            `${details?.productId}.pdf`,
                          details?.productId
                        );
                      }}
                    >
                      Download {pdfName}
                    </MenuItem>
                    <MenuItem
                      value={"remove_pdf"}
                      onClick={(event) => {
                        setAnchorEl(null);
                        if (
                          window.confirm(
                            "Are you sure you want to remove the pdf? This will remove it from the product itself. This cannot be undone."
                          )
                        ) {
                          deletePDF();
                        }
                      }}
                    >
                      Remove pdf
                    </MenuItem>
                  </>
                ) : (
                  <MenuItem
                    value={"Upload_pdf"}
                    onClick={(event) => {
                      setAnchorEl(null);
                      handlePdfClick();
                    }}
                  >
                    Upload PDF
                  </MenuItem>
                )}
              </Menu>
            </>
          )}
        </div>
      </TableCell>

      <TableCell align={"center"}>{details && details.quantity}</TableCell>

      <TableCell align={"center"}>{details && details.model.name}</TableCell>
      <TableCell align={"center"}>
        {(details &&
          (details?.customMaterial
            ? details.customMaterial.name
            : details.standardMaterial
            ? details.standardMaterial.name
            : details.material)) ??
          "Loading..."}
        <br></br>
        {(details &&
          (details?.customFinish
            ? details.customFinish.name
            : details.finish)) ??
          "Loading..."}
      </TableCell>
      {/* <TableCell align={"center"}>
        <FormControl
          variant="standard"
          sx={{ m: 1, minWidth: 80 }}
          size="medium"
        >
          <Input
            endAdornment={<InputAdornment position="end">kr</InputAdornment>}
            type="text"
            placeholder={(
              (project as any).price ??
              (project as any).total ??
              0
            ).toString()}
            value={priceText}
            onBlur={(e) => handlePrice(e.target.value)}
            onChange={(e) => setPriceText(e.target.value)}
            onClick={(e) => e.stopPropagation()}
          />
        </FormControl>
      </TableCell> */}
    </TableRow>
  );
};

export default ReqOrderItemRow;
