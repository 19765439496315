import {
  FunctionComponent,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import {
  Container,
  Button,
  Typography,
  LinearProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Input,
  Switch,
  FormControlLabel,
  IconButton,
  Tooltip,
} from "@mui/material";
import { useHistory, useLocation } from "react-router";
import { apiGetOrder } from "../../util/network/Orders";
import { OrderItemType, OrderType } from "types/OrderTypes";
import { Materials, Finishes } from "util/MaterialUtils";
import AppStateContext from "contexts/AppStateContext";
import ReqOrderTable from "./ReqOrderTable";
import React from "react";
import TextField from "@mui/material/TextField";
import {
  CreateRequisitionItemType,
  CreateRequisitionType,
  RenderRequisitionType,
  RequisitionStatusEnum,
  RequisitionType,
  RequisitionTypeEnum,
  UpdateRequisitionType,
} from "types/RequisitionTypes";
import {
  ManufacturedProductDetailsType,
  ProductTypeEnum,
} from "types/products/ProductCommandsType";
import {
  apiCreateRequisition,
  apiPreviewRequisition,
  apiUpdateRequisition,
} from "util/network/Requisitions";
import { PartnerType } from "types/partners/PartnerTypes";
import { apiGetPartners } from "util/network/Partners";
import dayjs from "dayjs";
import { CartItemType, CartType } from "types/CartTypes";
import { apiGetCart } from "util/network/Carts";
import { useToast } from "contexts/ToastContext";
import { Download } from "@mui/icons-material";
import { apiGetMe } from "util/network/Users";

type PropsType = {
  prodObjIn?: OrderType | CartType;
  oldRequisition?: RequisitionType;
  closeRequisition: Function;
  prodType: "order" | "cart";
};

// Requisition component
const RequisitionComponent: FunctionComponent<PropsType> = ({
  prodObjIn,
  closeRequisition,
  oldRequisition,
  prodType,
}) => {
  const [id, setId] = useState<number>();
  const [prodObj, setProdObj] = useState<OrderType | CartType>();
  const [prodItems, setProdItems] = useState<(OrderItemType | CartItemType)[]>(
    []
  );

  const [loading, setLoading] = useState<boolean>(false);

  const { token, employees } = useContext(AppStateContext);
  const { addToast } = useToast();
  const [selectedProjects, setSelectedProjects] = useState<
    (CreateRequisitionItemType & {
      id?: number;
    })[]
  >([]);
  const location = useLocation();
  const [partner, setPartner] = React.useState<PartnerType>();
  const [price, setPrice] = useState<number>();
  const [partners, setPartners] = useState<PartnerType[]>([]);
  const [type, setType] = useState<RequisitionTypeEnum>(
    RequisitionTypeEnum.CNC
  );
  const [deadline, setDeadline] = useState<string>();
  const [deliveryMethod, setDeliveryMethod] = useState<string>("");
  const [message, setMessage] = useState<string>("");
  const [tempMessage, setTempMessage] = useState<string>("");
  const [title, setTitle] = useState<string>("");
  const [state, setState] = useState(RequisitionStatusEnum.REQUEST);
  const [sendEmail, setSendEmail] = useState<boolean>(true);
  const history = useHistory();
  const [emailOpen, setEmailOpen] = useState(false);
  const [sendFromEmail, setSendFromEmail] = useState("info@easypartz.com");
  const [email, setEmail] = useState("");
  const [useDeadline, setUseDeadline] = useState(true);

  const [timer, setTimer] = useState<NodeJS.Timeout>();

  const previewEmail = () => {
    const command: RenderRequisitionType = renderReqCommand();
    apiPreviewRequisition(token, command, "html")
      .then((res) => res.text())
      .then((res) => {
        document.getElementById("preview")!.innerHTML = res;
      });
    setEmailOpen(true);
  };

  useEffect(() => {
    if (partner) {
      previewEmail();
    }
  }, [
    selectedProjects,
    partner,
    deadline,
    useDeadline,
    deliveryMethod,
    message,
    type,
  ]);

  useEffect(() => {
    if (prodObjIn && prodObjIn.items.length > 0) {
      setProdObj(prodObjIn);
      if (
        (Object.values(RequisitionTypeEnum) as string[]).includes(
          prodObjIn.items[0].product.productType.toString()
        )
      ) {
        const convertedType = prodObjIn.items[0].product
          .productType as unknown as RequisitionTypeEnum;
        handleType(convertedType, prodObjIn);
      }
      // If prodObjIn.leadTimeEndDate exists use this, else if prodObjIn.leadTimeDate exists use this
      // else set deadline to today
      let tempDate = new Date().toISOString();
      if (prodType == "order") {
        tempDate = (prodObjIn as OrderType).leadTimeEndDate;
      } else {
        tempDate = (prodObjIn as CartType).leadTimeDate;
      }

      let date = dayjs(tempDate.split("T")[0]);
      // Subtract one day
      date = date.subtract(1, "day");
      setDeadline(date.format("YYYY-MM-DD"));
    } else if (!prodObjIn) {
      handleType(RequisitionTypeEnum.CUSTOM, undefined);
    }
  }, [prodObjIn]);

  // When order is loaded, filter after product types
  const typeSelection = ["CNC", "PRINT3D", "SHEET", "FINISH", "CUSTOM"];
  // useMemo(() => {
  //   // We return the distinct types of the products in the order
  //   const productTypes =
  //     orderIn?.items
  //       .map((item) => item.product.productType)
  //       .filter((value, index, self) => self.indexOf(value) === index) ?? [];
  //   return productTypes;
  //   return [...productTypes, "ANODIZE"];
  // }, [orderItems]);

  //update the price when a project is selected
  useEffect(() => {
    if (selectedProjects) {
      var newPrice = 0;
      selectedProjects.map((item) => {
        if (item.price) {
          newPrice += item.price;
        }
      });
      setPrice(newPrice);
    }
  }, [selectedProjects]);

  //Initiate the selected project with the projects from the requisition if it is an update
  useEffect(() => {
    if (prodItems && !oldRequisition) {
      let startprojects: CreateRequisitionItemType[] = [];
      prodItems?.map((item) => {
        let project: CreateRequisitionItemType = {
          productId: item.product.id,
        };
        if (prodType == "order") {
          project.orderItemId = item.id;
        } else {
          project.quoteItemId = item.id;
        }
        if (type == "FINISH") {
          project.price = 0;
        }
        startprojects.push(project);
      });
      setSelectedProjects(startprojects);
    }
  }, [prodItems, type]);
  //Get all partners and set the type to the type in requisition if it is an update
  useEffect(() => {
    apiGetMe(token)
      .then((data) => {
        setEmail(
          data.email || (employees.items[0] && employees.items[0].email) || ""
        );
      })
      .catch((error) => {
        console.error(error);
      });
    Materials.loadMaterials();
    Finishes.loadFinishes();
    getPartners();
    if (!location?.state) {
      if (prodObj) {
        const tempDate =
          (prodObj as any).leadTimeEndDate ??
          (prodObj as any).leadTimeDate ??
          new Date().toISOString();
        setDeadline(tempDate);
        //handleType(prodObj.items[0].product.productType, prodObj);
      } else {
        setLoading(false);
      }
    } else setProdObj(location.state);
  }, [location?.state, token]);

  //Set old requisition data if it is an update
  useEffect(() => {
    if (oldRequisition) {
      setLoading(true);
      setId(oldRequisition.id);
      setPartner(oldRequisition.partner);
      setDeadline(oldRequisition.deliveryDate);
      setDeliveryMethod(oldRequisition.deliveryMethod);
      setMessage(oldRequisition.message);
      setTempMessage(oldRequisition.message);
      setState(oldRequisition.state);
      if (oldRequisition.title) {
        setTitle(oldRequisition.title);
      }
      if (oldRequisition.orderId) {
        apiGetOrder(token, oldRequisition.orderId).then((res) => {
          setProdObj(res);
          handleType(oldRequisition.type, res);
          setLoading(false);
        });
      } else if (oldRequisition.quoteId) {
        apiGetCart(token, oldRequisition.quoteId).then((res) => {
          setProdObj(res);
          handleType(oldRequisition.type, res);
          setLoading(false);
        });
      } else {
        setLoading(false);
      }
      if (oldRequisition.items) {
        setSelectedProjects(
          oldRequisition.items.map((item) => ({
            id: item.id,
            productId: item.product.id,
            orderItemId: item.orderItemId,
            quoteItemId: item.quoteItemId,
            price: item.price,
          }))
        );
      }
    }
  }, [oldRequisition]);

  //Update price
  const changeItemPrice = (project: CreateRequisitionItemType) => {
    changeSelected(project, true);
  };

  //Change selected and update price if it is called form changeItemPrice
  const changeSelected = (
    project?: CreateRequisitionItemType,
    updatePrice?: boolean,
    selectAll?: boolean
  ) => {
    if (selectAll) {
      setSelectedProjects(
        prodItems.map((item) => {
          const createItem: CreateRequisitionItemType = {
            productId: item.product.id,
            price: 0,
          };
          if (prodType === "order") {
            createItem.orderItemId = item.id;
          } else {
            createItem.quoteItemId = item.id;
          }
          return createItem;
        })
      );
    } else if (selectAll == false) {
      setSelectedProjects([]);
    } else {
      if (project)
        setSelectedProjects((prevSelectedProjects) => {
          const projectExists = prevSelectedProjects.some(
            (selectedProject) => selectedProject.productId === project.productId
          );

          if (projectExists) {
            if (updatePrice) {
              return prevSelectedProjects.map((selectedProject) => {
                if (selectedProject.productId === project.productId) {
                  return {
                    ...selectedProject,
                    price: project.price,
                  };
                }
                return selectedProject;
              });
            } else {
              return prevSelectedProjects.filter(
                (selectedProject) =>
                  selectedProject.productId !== project.productId
              );
            }
          } else {
            return [...prevSelectedProjects, project];
          }
        });
    }
  };

  // Update selected partner
  const handlePartner = (event: SelectChangeEvent<number>) => {
    const index = partners.findIndex(
      (partner) => partner.id === event.target.value
    );
    const partner = partners[index];
    setPartner(partner);
    // if (prodObj) {
    //   handleType(partner.templateType, prodObj);
    // }
    setDeliveryMethod(partner.templateDelivery);
    setMessage(partner.templateDescription);
    setTempMessage(partner.templateDescription);
    console.log(partner);
  };

  // Handle type change
  const handleType = useCallback(
    (type: RequisitionTypeEnum, prodObjProp?: OrderType | CartType) => {
      setType(type);

      if (partner && partner.templateType !== type) {
        setPartner(undefined);
      }

      const items: (OrderItemType | CartItemType)[] = [];
      prodObjProp?.items.map((prodItem: OrderItemType | CartItemType) => {
        const prodtype = prodItem.product.productType;
        console.log(prodtype, type, prodItem);
        if (
          (prodtype as string) == (type as string) ||
          prodtype == ProductTypeEnum.CUSTOM
        ) {
          items.push(prodItem);
        } else if (
          type == RequisitionTypeEnum.FINISH &&
          (prodtype == ProductTypeEnum.CNC ||
            prodtype == ProductTypeEnum.PRINT3D ||
            prodtype == ProductTypeEnum.SHEET)
        ) {
          const finishes = [
            ...(
              (prodItem.product.details as ManufacturedProductDetailsType)
                .finish as any
            )
              .split(",")
              .filter((fin: string) => fin != "standard"),
          ];

          if (finishes?.length > 0) {
            items.push(prodItem);
          }
        }
      });
      setProdItems(items);
    },
    [prodObj]
  );

  // Get all partners
  const getPartners = () => {
    if (token) {
      apiGetPartners(token, 1, 1000000000).then((res) => {
        setPartners(res.items);
      });
    }
  };

  const createReqCommand = () => {
    let requisition: CreateRequisitionType;
    if (prodObj) {
      requisition = {
        partnerId: partner!.id,
        type: type,
        deadline: useDeadline ? deadline : undefined,
        deliveryMethod: deliveryMethod,
        message: message,
        items: selectedProjects.map((item) => {
          return {
            productId: item.productId,
            orderItemId: item.orderItemId,
            quoteItemId: item.quoteItemId,
            price: item.price,
          };
        }),
      };
      if (prodType == "order") {
        requisition.orderId = prodObj.id;
      } else {
        requisition.quoteId = prodObj.id;
      }
    } else {
      requisition = {
        partnerId: partner!.id,
        title: title,
        deadline: useDeadline ? deadline : undefined,
        deliveryMethod: deliveryMethod,
        message: message,
        items: selectedProjects.map((item) => {
          return {
            productId: item.productId,
            orderItemId: item.orderItemId,
            quoteItemId: item.quoteItemId,
            price: item.price,
          };
        }),
        type: "CUSTOM",
      };
    }
    return requisition;
  };

  const renderReqCommand = () => {
    let requisition: RenderRequisitionType;
    if (prodObj) {
      requisition = {
        id: oldRequisition?.id,
        partnerId: partner!.id,
        type: type,
        deadline: useDeadline ? deadline : undefined,
        deliveryMethod: deliveryMethod,
        message: message,
        items: selectedProjects.map((item) => {
          return {
            productId: item.productId,
            orderItemId: item.orderItemId,
            quoteItemId: item.quoteItemId,
            price: item.price,
          };
        }),
      };
      if (prodType == "order") {
        requisition.orderId = prodObj.id;
      } else {
        requisition.quoteId = prodObj.id;
      }
    } else {
      requisition = {
        partnerId: partner!.id,
        title: title,
        deadline: useDeadline ? deadline : undefined,
        deliveryMethod: deliveryMethod,
        message: message,
        items: selectedProjects.map((item) => {
          return {
            productId: item.productId,
            orderItemId: item.orderItemId,
            quoteItemId: item.quoteItemId,
            price: item.price,
          };
        }),
        type: "CUSTOM",
      };
    }
    return requisition;
  };

  // Create new requisition with order or without
  const handleCreated = () => {
    const command = createReqCommand();
    setLoading(true);
    try {
      apiCreateRequisition(token, command, sendEmail, sendFromEmail)
        .then((res) => {
          addToast({
            type: "success",
            message: "Requisition created",
          });
          closeRequisition();
          history.push("/outsourcing");
        })
        .catch((err) => {
          console.error(err);
          addToast({
            type: "error",
            message: "The requisition could not be created: " + err,
            keep: true,
          });
        });
    } catch (e) {
      console.log(e);
      addToast({
        type: "error",
        message: "Error on creation: " + e,
        keep: true,
      });
    }
  };
  console.log("selectedProjects", selectedProjects);
  // Update requisition
  const handleUpdate = () => {
    let requisition: UpdateRequisitionType;
    setLoading(true);
    if (id) {
      requisition = {
        deadline: deadline,
        deliveryMethod: deliveryMethod,
        message: message,
        state: state,
        items: selectedProjects,
      };
      try {
        apiUpdateRequisition(token, id, requisition, sendEmail, sendFromEmail)
          .then((res) => {
            closeRequisition();
            addToast({
              type: "success",
              message: "Requisition updated",
            });
          })
          .catch((err) => {
            addToast({
              type: "error",
              message: "Error on update: " + err,
              keep: true,
            });
          });
      } catch (e) {
        addToast({
          type: "error",
          message: "Error on update: " + e,
          keep: true,
        });
      }
    }
  };

  const handleDownloadPDF = () => {
    const command: RenderRequisitionType = createReqCommand();
    apiPreviewRequisition(token, command, "pdf")
      .then((response) => response.blob())
      .then((blob) => {
        if (blob instanceof Blob) {
          const url = window.URL.createObjectURL(
            new Blob([blob], { type: "application/pdf" })
          );

          // open the PDF in a new window and print
          const win = window.open("", "_blank");
          if (win) {
            win.document.write(`
            <iframe src="${url}" frameborder="0" style="border:0; top:0; left:0; bottom:0; right:0; width:100%; height:100%;" onload="window.print();"></iframe>
          `);
          }

          // to download the PDF
          const a = document.createElement("a");
          a.href = url;
          a.download = "RequisitionPreview.pdf";
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(url);
        } else {
          console.error("Expected blob but received:", blob);
        }
      })
      .catch((error) => {
        console.error("Error fetching and downloading PDF:", error);
      });
  };

  return (
    <>
      <Container
        sx={{
          marginTop: "2em",
          height: "900px",
          maxWidth: "1800px",
        }}
        maxWidth={false}
      >
        {loading ? <LinearProgress style={{ margin: "0.5rem" }} /> : ""}
        <div>
          <form
            id="requisitionForm"
            onSubmit={(event) => {
              if (oldRequisition) {
                handleUpdate();
              } else {
                handleCreated();
              }
              event.preventDefault();
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
              }}
            >
              <div style={{ paddingRight: "2rem" }}>
                <Typography
                  sx={{
                    width: "100%",
                    textAlign: "center",
                    fontWeight: "bold",
                    fontSize: "x-large",
                  }}
                >
                  {oldRequisition ? " Update requisition" : "New requisition"}
                </Typography>
                {prodObj ? (
                  <Typography
                    sx={{
                      width: "100%",
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "large",
                    }}
                  >
                    {prodType === "order"
                      ? `Ordernumber: ${(prodObj as OrderType)?.orderNo}`
                      : `Quotenumber: ${(prodObj as CartType)?.id}`}
                  </Typography>
                ) : (
                  <Typography
                    sx={{
                      textAlign: "center",
                      fontSize: "large",
                      marginTop: "60px",
                    }}
                  >
                    <FormControl
                      required
                      variant="standard"
                      sx={{ minWidth: 550, marginBottom: "10px" }}
                      size="medium"
                    >
                      <InputLabel id="demo-simple-select-label">
                        Title
                      </InputLabel>
                      <Input
                        value={title}
                        onChange={(e) => {
                          setTitle(e.target.value);
                        }}
                        inputProps={{ maxLength: 80 }}
                      />
                    </FormControl>
                  </Typography>
                )}
                <br></br>
                <div style={{ textAlign: "right", paddingBottom: "1rem" }}>
                  <Button
                    color={"error"}
                    variant="contained"
                    sx={{ marginRight: "1em" }}
                    onClick={(e) => closeRequisition()}
                  >
                    Annuller
                  </Button>
                  <Button
                    variant="contained"
                    type="submit"
                    form="requisitionForm"
                    sx={{ marginRight: "1em" }}
                  >
                    {oldRequisition
                      ? "Update requisition"
                      : "Create Requisition"}
                  </Button>
                  <Button
                    variant="contained"
                    sx={{
                      background: "#E0A900",
                      "&:hover": {
                        background: "#b38700",
                      },
                    }}
                    onClick={() => {
                      if (!partner) {
                        addToast({
                          type: "error",
                          message: "Select a partner",
                          keep: true,
                        });
                      } else {
                        previewEmail();
                      }
                    }}
                  >
                    Preview Email
                  </Button>
                </div>
                <br></br>
                {/* Send email toggle */}
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <div style={{ paddingRight: "3rem" }}>
                    <Typography
                      sx={{
                        textAlign: "left",
                        fontSize: "large",
                      }}
                    >
                      <FormControlLabel
                        control={
                          <Switch
                            checked={sendEmail}
                            onChange={(e) => setSendEmail(e.target.checked)}
                            color="primary"
                          />
                        }
                        labelPlacement="start"
                        label="Send email to partner"
                      />
                    </Typography>

                    <Typography
                      sx={{
                        width: "100%",
                        textAlign: "left",
                        fontWeight: "bold",
                        fontSize: "small",
                      }}
                    >
                      <InputLabel
                        id="demo-simple-select-label"
                        sx={{
                          fontSize: "small",
                        }}
                      >
                        Type
                      </InputLabel>
                      <Select
                        size="small"
                        style={{ minWidth: "200px" }}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label={"Type"}
                        value={type}
                        onChange={(e) => {
                          const type = e.target.value as RequisitionTypeEnum;
                          handleType(type, prodObj);
                        }}
                        disabled={oldRequisition ? true : false}
                      >
                        {typeSelection.map((type: string) => (
                          <MenuItem key={type} value={type}>
                            {type}
                          </MenuItem>
                        ))}
                      </Select>
                    </Typography>
                    <Typography
                      sx={{
                        textAlign: "left",
                        fontSize: "small",
                      }}
                    >
                      {/* {!oldRequisition ? ( */}
                      <InputLabel
                        id="demo-simple-select-label"
                        sx={{
                          fontSize: "small",
                        }}
                      >
                        Partner
                      </InputLabel>
                      {/* ) : null} */}
                      <FormControl
                        required
                        variant="outlined"
                        sx={{ minWidth: 200, paddingBottom: "0.5rem" }}
                        size="small"
                      >
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={partner?.id}
                          size="small"
                          style={{ minWidth: "200px" }}
                          renderValue={(value) => {
                            if (partner) {
                              return (
                                partner.info?.company +
                                ", " +
                                partner.info.firstName +
                                " " +
                                partner.info.firstName
                              );
                            }
                            const index = partners.findIndex(
                              (partner) => partner.id === value
                            );
                            return (
                              partners[index].info?.company +
                              ", " +
                              partners[index].info.firstName +
                              " " +
                              partners[index].info.firstName
                            );
                          }}
                          onChange={handlePartner}
                          disabled={oldRequisition ? true : false}
                        >
                          {partners
                            .filter((partner) => partner.templateType === type)
                            .sort((a, b) => {
                              return a.info?.company.localeCompare(
                                b.info?.company
                              );
                            })
                            .map((partner: PartnerType) => (
                              <MenuItem key={partner.id} value={partner.id}>
                                {partner.info?.company +
                                  ", " +
                                  partner.info.firstName +
                                  " " +
                                  partner.info.lastName +
                                  ", " +
                                  partner.templateType}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                    </Typography>
                    {/* <br></br> */}
                    <Typography
                      sx={{
                        textAlign: "left",
                        fontSize: "small",
                        paddingBottom: "0.5rem",
                      }}
                    >
                      <InputLabel
                        id="demo-simple-select"
                        sx={{
                          fontSize: "small",
                        }}
                      >
                        Deadline
                      </InputLabel>
                      <FormControl
                        variant="outlined"
                        sx={{ minWidth: 200 }}
                        size="small"
                      >
                        <TextField
                          disabled={!useDeadline}
                          size="small"
                          id="demo-simple-select"
                          type={"date"}
                          value={deadline}
                          onChange={(e) => {
                            if (e.target.value !== "")
                              setDeadline(e.target.value);
                            else setDeadline(undefined);
                          }}
                          required={false}
                        />
                      </FormControl>
                      <Tooltip title="Use deadline">
                        <Switch
                          defaultChecked={useDeadline}
                          onChange={(e) => setUseDeadline(e.target.checked)}
                        />
                      </Tooltip>
                    </Typography>
                    <Typography
                      sx={{
                        textAlign: "left",
                        fontSize: "small",
                        paddingBottom: "0.5rem",
                      }}
                    >
                      <InputLabel
                        id="demo-simple-select-label"
                        sx={{
                          fontSize: "small",
                        }}
                      >
                        Send email from
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={sendFromEmail}
                        label="Send email from"
                        onChange={(e) => setSendFromEmail(e.target.value)}
                        size="small"
                        style={{ minWidth: "200px" }}
                      >
                        <MenuItem value="info@easypartz.com">
                          info@easypartz.com
                        </MenuItem>
                        {email && <MenuItem value={email}>{email}</MenuItem>}
                      </Select>
                    </Typography>
                    {/* <br></br> */}
                    <Typography
                      sx={{
                        textAlign: "left",
                        fontSize: "large",
                        // minWidth: 350,
                        paddingBottom: "0.5rem",
                      }}
                    >
                      <InputLabel
                        id="demo-simple-select-label"
                        sx={{
                          fontSize: "small",
                        }}
                      >
                        Delivery method
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Delivery Method"
                        value={deliveryMethod}
                        onChange={(event) =>
                          setDeliveryMethod(event.target.value)
                        }
                        size="small"
                        style={{ minWidth: "200px" }}
                      >
                        <MenuItem value={"Delivery"}>Delivery</MenuItem>
                        <MenuItem value={"Pickup"}>Pickup</MenuItem>
                      </Select>
                    </Typography>
                  </div>
                  {/* <br></br> */}

                  <FormControl
                    variant="standard"
                    sx={{ m: 1, minWidth: 400 }}
                    size="medium"
                  >
                    <TextField
                      multiline
                      rows={8}
                      placeholder="Write a message to the partner here..."
                      variant="filled"
                      value={tempMessage}
                      onChange={(e) => {
                        if (timer) {
                          clearTimeout(timer);
                        }
                        setTempMessage(e.target.value);
                        setTimer(
                          setTimeout(() => {
                            setMessage(e.target.value);
                          }, 2000)
                        );
                      }}
                      onBlur={() => {
                        if (timer) {
                          clearTimeout(timer);
                        }
                        setMessage(tempMessage);
                      }}
                      style={{ background: "#fff !important" }}
                    />
                  </FormControl>
                  <br></br>
                </div>

                <>
                  <Typography
                    sx={{
                      width: "100%",
                      textAlign: "center",
                      fontSize: "large",
                    }}
                  >
                    <br></br>
                  </Typography>

                  <ReqOrderTable
                    selectedItems={selectedProjects}
                    prodItems={prodItems}
                    changeSelected={changeSelected}
                    changeItemPrice={changeItemPrice}
                    type={prodType}
                  ></ReqOrderTable>
                  <br></br>
                </>
              </div>

              {/* {prodObj ? (
              <Typography
                sx={{
                  textAlign: "center",
                  fontSize: "large",
                }}
              >
                <FormControl
                  variant="standard"
                  sx={{ minWidth: 250, marginBottom: "10px" }}
                  size="medium"
                >
                  <InputLabel id="demo-simple-select-label">
                    Samlet Pris
                  </InputLabel>
                  <Input
                    endAdornment={
                      <InputAdornment position="end">kr</InputAdornment>
                    }
                    value={price}
                    type="number"
                    disabled={true}
                    inputProps={{ min: 0 }}
                  />
                </FormControl>
              </Typography>
            ) : null} */}
              <div>
                {emailOpen ? (
                  <IconButton onClick={() => handleDownloadPDF()}>
                    <Download />
                  </IconButton>
                ) : (
                  ""
                )}
              </div>
              <div
                id="preview"
                style={{
                  border: "1px solid black",
                  minWidth: "700px",
                }}
              ></div>
            </div>
          </form>
        </div>
      </Container>
    </>
  );
};

export default RequisitionComponent;
