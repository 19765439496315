import React, { useContext, useEffect, useState } from "react";
// import styles from "./editOtherProduct.module.css";
import styles from "../CreateOtherProductModal/CreateOtherProductModal.module.css";
import AppStateContext from "contexts/AppStateContext";
import {
  ProductVMType,
  UpdateOtherProductType,
} from "types/products/ProductCommandsType";

type CreateOtherProductModalProps = {
  onSubmit: (result: UpdateOtherProductType) => void;
  onCancel: () => void;
  product: ProductVMType;
  initialName?: string;
  initialPrice?: string;
  initialWeight?: string;
  initialDescription?: string;
  isModalOpen: boolean;
  setIsModalOpen: Function;
};

const EditOtherProduct: React.FC<CreateOtherProductModalProps> = ({
  onSubmit,
  onCancel,
  initialName,
  initialDescription,
  initialPrice,
  initialWeight,
  isModalOpen,
  setIsModalOpen,
}) => {
  const { token } = useContext(AppStateContext);
  const [name, setName] = useState(initialName ?? "");
  const [price, setPrice] = useState(initialPrice ?? "");
  const [weight, setWeight] = useState(initialWeight ?? "");
  const [description, setDescription] = useState(initialDescription ?? "");
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    setIsModalOpen(false);
    if (!token) return;
    setIsLoading(true);
    try {
      const updatedFields: Partial<UpdateOtherProductType> = {};
      if (name && name !== initialName) updatedFields.name = name;
      if (price && price !== initialPrice) updatedFields.price = Number(price);
      if (weight && weight !== initialWeight)
        updatedFields.weight = Number(weight);
      if (description && description !== initialDescription)
        updatedFields.description = description;
      if (Object.keys(updatedFields).length > 0) {
        const command: UpdateOtherProductType = updatedFields;
        onSubmit(command);
      }
      setIsLoading(false);
      setIsModalOpen(false);
      setName("");
      setPrice("");
      setWeight("");
      setDescription("");
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (isModalOpen) {
      setName(initialName ?? "");
      setPrice(initialPrice ?? "");
      setWeight(initialWeight ?? "");
      setDescription(initialDescription ?? "");
    } else {
      setName("");
      setPrice("");
      setWeight("");
      setDescription("");
    }
  }, [
    isModalOpen,
    initialName,
    initialPrice,
    initialWeight,
    initialDescription,
  ]);

  return (
    <>
      <div className={styles.modalHeader}>
        <h2>Create Other Product</h2>
      </div>
      <div className={styles.modalBody}>
        <div className={styles.formGroup}>
          <label>Name:</label>
          <input
            type="text"
            className={styles.inputField}
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </div>
        <div className={styles.formGroup}>
          <label>Price:</label>
          <input
            type="number"
            className={styles.inputField}
            value={price}
            onChange={(e) => setPrice(e.target.value)}
          />
        </div>
        <div className={styles.formGroup}>
          <label>Weight:</label>
          <input
            type="number"
            className={styles.inputField}
            value={weight}
            onChange={(e) => setWeight(e.target.value)}
          />
        </div>
        <div className={styles.formGroup}>
          <label>Description:</label>
          <textarea
            className={`${styles.inputField} ${styles.textarea}`}
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          ></textarea>
        </div>
      </div>
      <div className={styles.modalFooter}>
        <button
          type="button"
          className={styles.cancelButton}
          onClick={() => {
            onCancel();
          }}
        >
          Cancel
        </button>
        <button
          type="submit"
          className={styles.createButton}
          onClick={handleSubmit}
        >
          {isLoading ? "Saving..." : "Save Changes"}
        </button>
      </div>
    </>
  );
};
export default EditOtherProduct;
