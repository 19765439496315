import {
  Grid,
  Container,
  TableHead,
  Table,
  TableRow,
  TableCell,
  TableBody,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Typography,
  Box,
  Tooltip,
  CircularProgress,
} from "@mui/material";
import {
  useState,
  useEffect,
  useCallback,
  useReducer,
  useContext,
  useMemo,
} from "react";
import { Prompt, useHistory, useLocation, useParams } from "react-router";
import AccountInfoTable from "./orderedit_components/AccountInfoTable";
import CNCItemTableRow from "./orderedit_components/CNCItemTableRow";
import ShippingMethods from "./orderedit_components/ShippingMethods";
import { Save, Cancel, Check } from "@mui/icons-material";
import {
  apiAddCartItem,
  apiConfirmOrder,
  apiCreateDraftCartFromCart,
  apiGetCart,
  apiGetShippingMethods,
  apiLockCart,
  apiRemoveCartItem,
  apiUpdateCartInfo,
  apiUpdateCartLeadTime,
} from "../../util/network/Carts";
import ListReducer, { ListReducerEnum } from "../../util/ListReducer";
import { LeadOptionsType } from "../../types/CheckoutTypes";
import AddressFields from "./orderedit_components/AddressFields";
import AppStateContext from "../../contexts/AppStateContext";
import UserModal from "./orderedit_components/UserModal";
import PaymentMethods from "./orderedit_components/PaymentMethods";
import {
  apiGetOrder,
  apiRefreshOrderFromQuote,
  apiSendOrderEmail,
} from "util/network/Orders";
import { apiUpdateUser } from "util/network/Users";
import { UserType } from "../../types/users/UserTypes";
import {
  CartItemType,
  CartStatusEnum,
  CartTotalsType,
  CartType,
  ShippingMethodType,
} from "../../types/CartTypes";
import { AddressType } from "../../types/AddressTypes";
import { apiCreateModel, apiUploadModelFile } from "../../util/network/Models";
import AmountTables from "./orderedit_components/AmountTables";
import SaveBillingAddressDialog from "components/Dialog/SaveBillingAddressDialog";
import { UserUpdateType } from "types/users/UserCommandsType";
import { OrderType } from "types/OrderTypes";
import {
  CreateCNCProductType,
  CreatePrint3DProductType,
  CreateSheetProductType,
  ProductTypeEnum,
  UpdateCNCProductType,
  UpdatePrint3DProductType,
  UpdateSheetProductType,
} from "types/products/ProductCommandsType";
import { apiCreateProduct, apiUpdateProduct } from "util/network/Products";
import Print3DItemTableRow from "./orderedit_components/Print3DItemTableRow";
import CreateOtherProductModal from "components/CreateOtherProductModal";
import OtherItemTableRow from "./orderedit_components/OtherItemTableRow";
import Modal from "components/Modals/Modal";
import SheetItemTableRow from "./orderedit_components/SheetItemTableRow";
import BasicDropdownButton from "components/BasicDropdownButton";
import { useToast } from "contexts/ToastContext";
import DraggableButton from "components/CustomButtons/DraggableButton";
import LoadingModal from "components/Modals/LoadingModal";
import { CustomMaterialType } from "types/products/ProductOptionsType";
import MatSelector from "./orderedit_components/MatSelector";
import CustomMaterialSelector from "./orderedit_components/CustomMaterialSelector";
import Checkbox from "components/Checkbox/Checkbox";
import FeeItemTableRow from "./orderedit_components/FeeItemTableRow";
import { CustomFinishType } from "tooldb/src/types/products/ProductOptionsType";
import CustomFinishSelector from "./orderedit_components/CustomFinishSelector";
import FinishSelector from "./orderedit_components/FinishSelector";
import DummyProductModal from "components/Modals/DummyProductModal";
import styles from "../order_management/orderedit_components/Styles/loginPageStyle.module.css";

type ParamsType = {
  id: string;
};

const DraftEdit = () => {
  const { token, setShowLoadingModal } = useContext(AppStateContext);

  const { addToast } = useToast();

  const history = useHistory();

  const { id } = useParams<ParamsType>();
  const location = useLocation();

  const cartId = parseInt(id);

  const [cart, setCart] = useState<CartType>();

  const [cartItems, cartItemsDispatch] = useReducer(
    ListReducer<CartItemType>("id"),
    []
  );
  const [uploadingFiles, setUploadingFiles] = useState<
    { name: string; tech: ProductTypeEnum; loading: boolean }[]
  >([]);
  const cncItems = useMemo(
    () =>
      cartItems.filter(
        (item) => item.product.productType === ProductTypeEnum.CNC
      ),
    [cartItems]
  );
  const print3DItems = useMemo(
    () =>
      cartItems.filter(
        (item) => item.product.productType === ProductTypeEnum.PRINT3D
      ),
    [cartItems]
  );
  const sheetItems = useMemo(
    () =>
      cartItems.filter(
        (item) => item.product.productType === ProductTypeEnum.SHEET
      ),
    [cartItems]
  );
  const otherItems = useMemo(
    () =>
      cartItems.filter(
        (item) => item.product.productType === ProductTypeEnum.OTHER
      ),
    [cartItems]
  );
  const [feeItems, feeItemsDispatch] = useReducer(
    ListReducer<CartItemType>("id"),
    []
  );

  const [cartTotals, setCartTotals] = useState<CartTotalsType>();

  const [showLoader, setShowLoader] = useState<boolean>(true);

  const [cartPONumber, setCartPONumber] = useState<string>();
  const [shippingMethod, setLocalShippingMethod] = useState<string>();
  const [isCustomShippingMethod, setIsCustomShippingMethod] =
    useState<boolean>();
  const [invoiceEmail, setInvoiceEmail] = useState<string>();
  const [leadtimeOption, setLeadtimeOption] =
    useState<LeadOptionsType>("STANDARD");
  const [leadTimeDate, setLeadTimeDate] = useState<string>();
  const [saveLoad, setSaveLoad] = useState<boolean>(false);
  const [showSendEmailModal, setShowSendEmailModal] = useState<boolean>(false);
  const [showDummyProductModal, setShowDummyProductModal] =
    useState<boolean>(false);

  const [message, setMessage] = useState<string>();
  const [internalMessage, setInternalMessage] = useState<string>();

  const reviewMode = cart?.status === CartStatusEnum.REVIEW;
  const quoteAcceptMode = cart?.status === CartStatusEnum.LOCKED;

  const [customer, _setCustomer] = useState<UserType>();

  const setCustomer = (customer: UserType) => {
    _setCustomer(customer);
    apiUpdateCartInfo(token, cartId, {
      authorId: customer.id,
    });
  };

  const fillAddressFields = () => {
    _setCustomer(customer);
    if (customer && customer.defaultBillingAddress) {
      let customerAddress: AddressType | undefined =
        customer.defaultBillingAddress;
      setBillingFirstName(customerAddress.firstName);
      setBillingLastName(customerAddress.lastName);
      setBillingEmail(customerAddress.email);
      setBillingAddressName(customerAddress?.street);
      setBillingCity(customerAddress.city);
      setBillingCompany(customerAddress.company);
      setBillingCvr(customerAddress.vat);
      setBillingPhone(customerAddress.phone);
      setBillingZipCode(customerAddress.postalCode);
      customerAddress = customer.defaultShippingAddress;
      setShippingFirstName(customerAddress?.firstName);
      setShippingLastName(customerAddress?.lastName);
      setShippingEmail(customerAddress?.email);
      setShippingAddressName(customer.defaultShippingAddress?.street);
      setShippingCity(customerAddress?.city);
      setShippingCompany(customerAddress?.company);
      setShippingCvr(customerAddress?.vat);
      setShippingPhone(customerAddress?.phone);
      setShippingZipCode(customerAddress?.postalCode);
    }
    if (customer) {
      setInvoiceEmail(customer.invoiceEmail);
      setBillingFirstName(customer.firstName);
      setBillingLastName(customer.lastName);
      setBillingEmail(customer.email);
      setShippingFirstName(customer?.firstName);
      setShippingLastName(customer?.lastName);
      setShippingEmail(customer.email);
    }
    if (customer)
      apiUpdateCartInfo(token, cartId, {
        authorId: customer.id,
      });
  };

  const [shippingCompany, setShippingCompany] = useState<string>();
  const [shippingCvr, setShippingCvr] = useState<string>();
  const [shippingFirstName, setShippingFirstName] = useState<string>();
  const [shippingLastName, setShippingLastName] = useState<string>();
  const [shippingAddressName, setShippingAddressName] = useState<string>();
  const [shippingZipCode, setShippingZipCode] = useState<string>();
  const [shippingCity, setShippingCity] = useState<string>();
  const [shippingPhone, setShippingPhone] = useState<string>();
  const [shippingEmail, setShippingEmail] = useState<string>();

  const [inputErr, setInputErr] = useState<boolean>(false);
  const [errorNames, setErrorNames] = useState<string[]>([]);

  // state for saving an address popup
  const [openSaveAddress, setOpenSaveAddress] = useState(false);

  const [useSameShipping, setUseSameShipping] = useState<boolean>();

  const [billingCompany, setBillingCompany] = useState<string>();
  const [billingCvr, setBillingCvr] = useState<string>();
  const [billingFirstName, setBillingFirstName] = useState<string>();
  const [billingLastName, setBillingLastName] = useState<string>();
  const [billingAddressName, setBillingAddressName] = useState<string>();

  const [billingZipCode, setBillingZipCode] = useState<string>();
  const [billingCity, setBillingCity] = useState<string>();
  const [billingPhone, setBillingPhone] = useState<string>();
  const [billingEmail, setBillingEmail] = useState<string>();
  const [priceLoad, setPriceLoad] = useState<boolean>(false);
  const [EAN, setEAN] = useState<string>("");

  const [paymentMethod, setPaymentMethod] = useState<string>("banktransfer");

  const [orderEditing, setOrderEditing] = useState<OrderType>();

  // Assuming the initial state of abortController is null and it's updated within a component or hook
  const [abortController, setAbortController] =
    useState<AbortController | null>(null);

  const isAnyRowSelected = (checkedItems: boolean[]): boolean => {
    return checkedItems.some((item) => item === true);
  };

  // for cnc
  const [selectedMaterialCnc, setSelectedMaterialCnc] = useState<string | null>(
    null
  );
  const [customMaterialCnc, setCustomMaterialCnc] = useState<
    CustomMaterialType | undefined
  >();
  const [customFinishCnc, setCustomFinishCnc] = useState<
    CustomFinishType | undefined
  >();
  const [selectedFinishCnc, setSelectedFinishCnc] = useState<string[] | null>(
    null
  );
  const [selectAllCheckCnc, setSelectAllCheckCnc] = useState(false);
  const [checkedItemsCnc, setCheckedItemsCnc] = useState<boolean[]>(
    cartItems
      .filter((item) => item.product.productType === ProductTypeEnum.CNC)
      .map((item) => false)
  );

  const handleCncChange = (index: number, isChecked: boolean) => {
    const updatedCheckedItems = [...checkedItemsCnc];
    updatedCheckedItems[index] = isChecked;
    setCheckedItemsCnc(updatedCheckedItems);

    // const allSelected = updatedCheckedItems.every((item) => item === true);

    // setSelectAllCheckCnc(allSelected);
    if (!isChecked) {
      setSelectedRowsMaterialsCnc((prevMaterials) => {
        const newMaterials = { ...prevMaterials };
        delete newMaterials[index];
        return newMaterials;
      });
    }
  };

  const handleSelectAllCnc = (e: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = e.target.checked;
    setSelectAllCheckCnc(isChecked);
    setCheckedItemsCnc(
      cartItems
        .filter((item) => item.product.productType === ProductTypeEnum.CNC)
        .map((item) => isChecked)
    );
    if (!isChecked) {
      setSelectedRowsMaterialsCnc({});
      setSelectedRowsFinishesCnc({});
    }
  };

  const [selectedRowsMaterialsCnc, setSelectedRowsMaterialsCnc] = useState<{
    [index: number]: string;
  }>({});
  const [selectedRowsFinishesCnc, setSelectedRowsFinishesCnc] = useState<{
    [index: number]: string[];
  }>({});

  const materialDisplayCnc = useMemo(() => {
    const selectedMaterials = Object.entries(selectedRowsMaterialsCnc)
      .filter(([index]) => checkedItemsCnc[parseInt(index)])
      .map(([, material]) => material);

    // console.log(selectedMaterials);

    const allMatchParent = selectedMaterials.every(
      (mat) => mat === selectedMaterialCnc
    );
    const uniqueMaterials = new Set(selectedMaterials);

    if (selectedMaterials.length === 0) {
      return "Material";
    } else if (allMatchParent && selectedMaterialCnc) {
      return selectedMaterialCnc;
    } else if (
      uniqueMaterials.size > 1 ||
      (uniqueMaterials.size === 1 && !allMatchParent)
    ) {
      return "Multiple";
    } else {
      return selectedMaterials[0];
    }
  }, [selectedRowsMaterialsCnc, checkedItemsCnc, selectedMaterialCnc]);

  const finishDisplayCnc = useMemo(() => {
    const selectedFinishesArrays = Object.entries(selectedRowsFinishesCnc)
      .filter(([index]) => checkedItemsCnc[parseInt(index, 10)])
      .map(([, finishes]) => finishes);

    const allFinishes = selectedFinishesArrays.flat();

    const uniqueFinishes = new Set(allFinishes);

    if (selectedFinishesArrays.length === 0) {
      return ["Finish"];
    } else if (uniqueFinishes.size === 1) {
      const finishesArray = Array.from(uniqueFinishes);
      console.log("finishesArray", finishesArray);
      return ["Multiple"];
    } else {
      return ["Multiple"];
    }
  }, [selectedRowsFinishesCnc, checkedItemsCnc]);

  // for print3d
  const [selectedMaterialPrint3D, setSelectedMaterialPrint3D] = useState<
    string | null
  >(null);
  const [customMaterialPrint3D, setCustomMaterialPrint3D] = useState<
    CustomMaterialType | undefined
  >();
  const [customFinishPrint3D, setCustomFinishPrint3D] = useState<
    CustomFinishType | undefined
  >();
  const [selectedFinishPrint3D, setSelectedFinishPrint3D] = useState<
    string[] | null
  >(null);
  const [selectAllCheckPrint3D, setSelectAllCheckPrint3D] = useState(false);
  const [checkedItemsPrint3D, setCheckedItemsPrint3D] = useState<boolean[]>(
    print3DItems.map(() => false)
  );
  const [selectedRowsMaterialsPrint3D, setSelectedRowsMaterialsPrint3D] =
    useState<{
      [index: number]: string;
    }>({});
  const [selectedRowsFinishesPrint3D, setSelectedRowsFinishesPrint3D] =
    useState<{
      [index: number]: string[];
    }>({});

  const handlePrint3DChange = (index: number, isChecked: boolean) => {
    const updatedCheckedItems = [...checkedItemsPrint3D];
    updatedCheckedItems[index] = isChecked;
    setCheckedItemsPrint3D(updatedCheckedItems);

    if (!isChecked) {
      setSelectedRowsMaterialsPrint3D((prevMaterials) => {
        const newMaterials = { ...prevMaterials };
        delete newMaterials[index];
        return newMaterials;
      });
    }
  };

  const handleSelectAllPrint3D = (e: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = e.target.checked;
    setSelectAllCheckPrint3D(isChecked);
    setCheckedItemsPrint3D(
      cartItems
        .filter((item) => item.product.productType === ProductTypeEnum.PRINT3D)
        .map((item) => isChecked)
    );
    if (!isChecked) {
      setSelectedRowsMaterialsPrint3D({});
      setSelectedRowsFinishesPrint3D({});
    }
  };

  const isPrint3DSelected = isAnyRowSelected(checkedItemsPrint3D);
  const print3DMaterialValue = isPrint3DSelected
    ? selectedMaterialPrint3D
    : "Material";

  // for sheet
  const [selectedMaterialSheet, setSelectedMaterialSheet] = useState<
    string | null
  >(null);
  const [customMaterialSheet, setCustomMaterialSheet] = useState<
    CustomMaterialType | undefined
  >();
  const [customFinishSheet, setCustomFinishSheet] = useState<
    CustomFinishType | undefined
  >();
  const [selectedFinishSheet, setSelectedFinishSheet] = useState<
    string[] | null
  >(null);
  const [selectAllCheckSheet, setSelectAllCheckSheet] = useState(false);
  const [checkedItemsSheet, setCheckedItemsSheet] = useState<boolean[]>(
    sheetItems.map(() => false)
  );

  const handleSheetChange = (index: number, isChecked: boolean) => {
    const updatedCheckedItems = [...checkedItemsSheet];
    updatedCheckedItems[index] = isChecked;
    setCheckedItemsSheet(updatedCheckedItems);
  };

  const handleSelectAllSheet = (e: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = e.target.checked;
    setSelectAllCheckSheet(isChecked);
    setCheckedItemsSheet(
      cartItems
        .filter((item) => item.product.productType === ProductTypeEnum.SHEET)
        .map((item) => isChecked)
    );
  };

  const updateCartInfo = useCallback(
    (refreshItems?: boolean) => {
      console.log("Updating cart info");
      if (token === "") return Promise.resolve(false);

      // Abort the previous request
      if (abortController) {
        abortController.abort();
      }

      // Create a new AbortController for the new request
      const newAbortController = new AbortController();
      setAbortController(newAbortController);

      return apiGetCart(token, cartId, newAbortController.signal)
        .then((newCart: CartType) => {
          const totalsInfo: CartTotalsType = {
            discount: 0,
            shippingTax: newCart.shippingTax,
            shipping: newCart.shipping,
            subtotal: newCart.subtotal,
            subtotalTax: newCart.subtotalTax,
            total: newCart.total,
            totalTax: newCart.totalTax,
          };
          if (
            refreshItems ||
            newCart.items.length !== cartItems.length + feeItems.length
          ) {
            console.log("Updating cart items");
            setCartItems(newCart.items);
          }
          setCart(newCart);
          //setCartItems(cart.items);
          setCartTotals(totalsInfo);
          setLeadtimeOption(newCart.leadTimeOption);
          setLeadTimeDate(newCart.leadTimeDate);
          setShowLoadingModal(false);
          setShowLoader(false);
          return true;
        })
        .catch((err: any) => {
          if (err.name === "AbortError") {
            console.log("Fetch aborted:", err);
          } else {
            console.error("Fetch error:", err);
          }
          return false;
        });
    },
    [cartId, token, abortController, cartItems, feeItems]
  );

  // Don't forget to clean up
  useEffect(() => {
    return () => {
      abortController?.abort();
    };
  }, [abortController]);

  const addCartItem = useCallback(
    (productId: number, quantity?: number) => {
      // Abort the previous request
      if (abortController) {
        abortController.abort();
      }
      return apiAddCartItem(token, cartId, productId, quantity)
        .then((item) => {
          //cartItemsDispatch({ type: ListReducerEnum.ADD, value: item });
          updateCartInfo();
          return true;
        })
        .catch((error) => {
          addToast({
            type: "error",
            message: "Could not add item correctly to cart" + error,
            keep: true,
          });
          return false;
        });
    },
    [id, token]
  );

  const removeCartItem = useCallback(
    (item: CartItemType) => {
      // Abort the previous request
      if (abortController) {
        abortController.abort();
      }
      return apiRemoveCartItem(token, cartId, item.id)
        .then((res) => {
          // cartItemsDispatch({
          //   type: ListReducerEnum.REMOVE,
          //   value: item,
          // });
          updateCartInfo();
          return true;
        })
        .catch((err) => false);
    },
    [cartId, token]
  );

  const updateCartItem = useCallback(
    (item: CartItemType, refreshItems?: boolean) => {
      if (item.product.productType === ProductTypeEnum.FEE) {
        feeItemsDispatch({ type: ListReducerEnum.UPDATE, value: item });
      } else {
        cartItemsDispatch({ type: ListReducerEnum.UPDATE, value: item });
      }

      updateCartInfo(refreshItems);
    },
    [id, token, updateCartInfo]
  );

  const setCartItems = useCallback((items: CartItemType[]) => {
    const fees: CartItemType[] = [];
    const rest: CartItemType[] = [];
    items.forEach((item) => {
      if (item.product.productType === ProductTypeEnum.FEE) {
        fees.push(item);
      } else {
        rest.push(item);
      }
    });
    feeItemsDispatch({ type: ListReducerEnum.SET, value: fees });
    cartItemsDispatch({ type: ListReducerEnum.SET, value: rest });
  }, []);

  const addDraftItemFromModel = (file: File, type: ProductTypeEnum) => {
    setPriceLoad(true);
    setUploadingFiles((currentFiles) => [
      ...currentFiles,
      { name: file.name, tech: type, loading: true },
    ]);
    apiUploadModelFile(token, file, type)
      .then((res) => {
        return Promise.all(
          res.filesList.map((item) => {
            if (type === ProductTypeEnum.CNC) {
              const command: CreateCNCProductType = {
                modelId: item.id,
                quantity: 1,
                material: "alu-6082",
                finish: ["standard"],
                blueprint: null,
                comment: null,
                threads: [],
                productType: ProductTypeEnum.CNC,
              };
              return apiCreateProduct(token, command).then((res) => {
                addCartItem(res.id, 1);
                setUploadingFiles((currentFiles) =>
                  currentFiles.map((f) =>
                    f.name === file.name ? { ...f, loading: false } : f
                  )
                );
              });
            } else if (type === ProductTypeEnum.PRINT3D) {
              const command: CreatePrint3DProductType = {
                modelId: item.id,
                quantity: 1,
                material: "MDP-120",
                finish: ["standard"],
                comment: null,
                threads: [],
                productType: ProductTypeEnum.PRINT3D,
              };
              return apiCreateProduct(token, command).then((res) => {
                addCartItem(res.id, 1);
                setUploadingFiles((currentFiles) =>
                  currentFiles.map((f) =>
                    f.name === file.name ? { ...f, loading: false } : f
                  )
                );
              });
            } else if (type === ProductTypeEnum.SHEET) {
              const command: CreateSheetProductType = {
                modelId: item.id,
                quantity: 1,
                material: "alu-5754",
                finish: ["standard"],
                blueprint: null,
                comment: null,
                threads: [],
                productType: ProductTypeEnum.SHEET,
              };
              return apiCreateProduct(token, command).then((res) => {
                addCartItem(res.id, 1);
                setUploadingFiles((currentFiles) =>
                  currentFiles.map((f) =>
                    f.name === file.name ? { ...f, loading: false } : f
                  )
                );
              });
            }
          })
        ).then((res) => {
          setPriceLoad(false);
          setUploadingFiles((currentFiles) =>
            currentFiles.map((f) =>
              f.name === file.name ? { ...f, loading: false } : f
            )
          );
        });
      })
      .catch((err) => {
        addToast({
          type: "error",
          message:
            "Something went wrong with the upload. Check the file and try again\n Error code: " +
            err,
          keep: true,
        });
        setUploadingFiles((currentFiles) =>
          currentFiles.map((f) =>
            f.name === file.name ? { ...f, loading: false } : f
          )
        );
        setPriceLoad(false);
      });
  };

  const setCartData = (cart: CartType) => {
    setCart(cart);
    setCartItems(cart.items);
    const totalsInfo: CartTotalsType = {
      discount: 0,
      shippingTax: cart.shippingTax,
      shipping: cart.shipping,
      subtotal: cart.subtotal,
      subtotalTax: cart.subtotalTax,
      total: cart.total,
      totalTax: cart.totalTax,
    };
    setCartTotals(totalsInfo);
    if (cart.billingAddress) {
      setBillingAddressName(billingAddressName ?? cart.billingAddress.street);
      setBillingCity(billingCity ?? cart.billingAddress.city);
      setBillingCompany(billingCompany ?? cart.billingAddress.company);
      setBillingCvr(billingCvr ?? cart.billingAddress.vat);
      setBillingEmail(billingEmail ?? cart.billingAddress.email);
      setBillingFirstName(billingFirstName ?? cart.billingAddress.firstName);
      setBillingLastName(billingLastName ?? cart.billingAddress.lastName);
      setBillingPhone(billingPhone ?? cart.billingAddress.phone);
      setBillingZipCode(billingZipCode ?? cart.billingAddress.postalCode);
    }
    if (cart.shippingAddress) {
      setShippingAddressName(
        shippingAddressName ?? cart.shippingAddress.street
      );
      setShippingCity(shippingCity ?? cart.shippingAddress.city);
      setShippingCompany(shippingCompany ?? cart.shippingAddress.company);
      setShippingCvr(shippingCvr ?? cart.shippingAddress.vat);
      setShippingEmail(shippingEmail ?? cart.shippingAddress.email);
      setShippingFirstName(shippingFirstName ?? cart.shippingAddress.firstName);
      setShippingLastName(shippingLastName ?? cart.shippingAddress.lastName);
      setShippingPhone(shippingPhone ?? cart.shippingAddress.phone);
      setShippingZipCode(shippingZipCode ?? cart.shippingAddress.postalCode);
    }
    if (useSameShipping == undefined) {
      // Now we check if the two addresses are the same and set the state accordingly
      if (
        cart.billingAddress &&
        cart.shippingAddress &&
        cart.billingAddress.street == cart.shippingAddress.street &&
        cart.billingAddress.city == cart.shippingAddress.city &&
        cart.billingAddress.company == cart.shippingAddress.company &&
        cart.billingAddress.vat == cart.shippingAddress.vat &&
        cart.billingAddress.email == cart.shippingAddress.email &&
        cart.billingAddress.firstName == cart.shippingAddress.firstName &&
        cart.billingAddress.lastName == cart.shippingAddress.lastName &&
        cart.billingAddress.phone == cart.shippingAddress.phone &&
        cart.billingAddress.postalCode == cart.shippingAddress.postalCode
      ) {
        setUseSameShipping(true);
      } else {
        setUseSameShipping(false);
      }
    }
    setCartPONumber((old) => old ?? cart.poNumber);
    setInvoiceEmail((old) => old ?? cart.invoiceEmail);
    setMessage((old) => old ?? cart.message);
    setInternalMessage((old) => old ?? cart.internalComment);
    setLeadtimeOption(cart.leadTimeOption);
    setLeadTimeDate(cart.leadTimeDate);
    setIsCustomShippingMethod(cart.isCustomShippingMethod);
    setLocalShippingMethod(cart.shippingMethod);
    setShowLoadingModal(false);
    setShowLoader(false);
  };

  const refreshCart = useCallback(() => {
    if (token == "") return;
    setShowLoader(true);
    apiGetShippingMethods(token, cartId).then((methods) => {
      setShippingMethods(methods);
    });
    return apiGetCart(token, cartId)
      .then((res) => {
        setPaymentMethod(res.paymentMethod || "banktransfer");
        setIsCustomShippingMethod(res.isCustomShippingMethod);
        setLocalShippingMethod(res.shippingMethod);
        setCartData(res);
        setShowLoader(false);
      })
      .catch((err) => {
        setShowLoader(false);
        addToast({
          type: "error",
          message: "An error has occured." + err,
          keep: true,
        });
      });
  }, [cartId, token, setCartData]);

  const changeLeadTime = useCallback(
    (leadTime: LeadOptionsType) => {
      if (leadTime == "CUSTOM") {
        setLeadtimeOption(leadTime);
      } else {
        setShowLoader(true);
        return apiUpdateCartLeadTime(token, cartId, leadTime)
          .then((res) => {
            setCartData(res);
            return true;
          })
          .catch((err) => {
            return false;
          });
      }
    },
    [token, cartId, setCartData]
  );

  const changeLeadTimeDate = useCallback(
    (date: string) => {
      setLeadTimeDate(date);
      if (date == "") return;
      setShowLoader(true);
      return apiUpdateCartLeadTime(token, cartId, "CUSTOM", date)
        .then((res) => {
          setCartData(res);
          return true;
        })
        .catch((err) => {
          return false;
        });
    },
    [token, cartId, setCartData]
  );

  const [shippingMethods, setShippingMethods] = useState<ShippingMethodType[]>(
    []
  );

  const updateQuoteStatus = useCallback(
    (status: CartStatusEnum) => {
      // TODO Does this really need to update all cart data?
      setShowLoader(true);
      return apiUpdateCartInfo(token, cartId, {
        status: status,
      }).then((res) => {
        setCartData(res);
        setShowLoadingModal(false);
        setShowLoader(false);
        addToast({
          type: "success",
          message: "Quote status updated",
        });
        return true;
      });
    },
    [token, cartId, setCartData]
  );

  const handleGoBack = () => {
    if (cart?.status == "UNSAVED") {
      if (
        window.confirm(
          "You have unsaved changes. Are you sure you want to go back?"
        )
      ) {
        history.goBack();
      }
    } else {
      history.goBack();
    }
  };

  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      if (cart?.status == "UNSAVED") {
        event.preventDefault();
        event.returnValue = ""; // This is required for Chrome compatibility
        return true;
      }
    };

    const handleMouseBackButton = (event: MouseEvent) => {
      if (event.button === 3) {
        // Check for the right mouse button (button code: 3)
        event.preventDefault();

        if (cart?.status == "UNSAVED") {
          if (
            window.confirm(
              "You have unsaved changes in your cart. Are you sure you want to go back?"
            )
          ) {
            history.goBack();
            return true;
          }
        }
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);
    window.addEventListener("mousedown", handleMouseBackButton);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
      window.removeEventListener("mousedown", handleMouseBackButton);
    };
  }, [cart?.status, history]);

  useEffect(() => {
    refreshCart();
  }, [token, cartId]);

  useEffect(() => {
    const orderEditing = location.state?.orderId;
    if (orderEditing) {
      const orderId = parseInt(orderEditing);
      apiGetOrder(token, orderId).then((res) => {
        if (res.id) {
          setOrderEditing(res);
          _setCustomer(res.author);
        }
      });
    }

    localStorage.setItem("lastDraft", id);
    if (token == "") return;
    apiGetCart(token, parseInt(id)).then((draft) => {
      const customerInfo = draft.author;
      if (!orderEditing) _setCustomer(customerInfo);
      setShowLoadingModal(false);
      setShowLoader(false);

      setMessage(draft.message);
      setInternalMessage(draft.internalComment);

      const billingAddress = draft.billingAddress;
      const customerAddress =
        customerInfo && customerInfo.defaultBillingAddress;
      if (billingAddress) {
        setBillingAddressName((billingAddress ?? customerAddress).street);
        setBillingCity((billingAddress ?? customerAddress).city);
        setBillingCompany((billingAddress ?? customerAddress).company);
        setBillingCvr((billingAddress ?? customerAddress).vat);
        setBillingEmail((billingAddress ?? customerAddress).email);
        setBillingFirstName((billingAddress ?? customerAddress).firstName);
        setBillingLastName((billingAddress ?? customerAddress).lastName);
        setBillingPhone((billingAddress ?? customerAddress).phone);
        setBillingZipCode((billingAddress ?? customerAddress).postalCode);
      }

      const shippingAddress = draft.shippingAddress;

      setShippingAddressName(shippingAddress?.street);
      setShippingCity(shippingAddress?.city);
      setShippingCompany(shippingAddress?.company);
      setShippingCvr(shippingAddress?.vat);
      setShippingEmail(shippingAddress?.email);
      setShippingFirstName(shippingAddress?.firstName);
      setShippingLastName(shippingAddress?.lastName);
      setShippingPhone(shippingAddress?.phone);
      setShippingZipCode(shippingAddress?.postalCode);
    });
  }, [id, token, location.state]);

  const updateShippingMethod = (method: string, price?: number) => {
    setSaveLoad(true);
    setLocalShippingMethod(method);
    let body = null;
    if (price !== undefined) {
      body = {
        customShippingMethod: {
          name: method,
          price: price,
        },
      };
    } else {
      body = {
        shippingMethod: method,
      };
    }
    return apiUpdateCartInfo(token, parseInt(id), body).then((res) => {
      setSaveLoad(false);
      return setCartData(res);
    });
  };

  const saveInfo = () => {
    console.log(message);
    if (
      billingCity &&
      billingCompany &&
      billingFirstName &&
      billingEmail &&
      billingLastName &&
      billingZipCode &&
      billingAddressName &&
      billingPhone &&
      billingCvr
    ) {
      const billingAddress: AddressType = {
        city: billingCity,
        company: billingCompany,
        countryId: "DK",
        firstName: billingFirstName,
        email: billingEmail,
        lastName: billingLastName,
        postalCode: billingZipCode,
        street: billingAddressName,
        phone: billingPhone,
        vat: billingCvr,
      };

      if (useSameShipping) {
        return apiUpdateCartInfo(token, cartId, {
          billingAddress: billingAddress,
          shippingAddress: undefined,
          sameAsBilling: useSameShipping,
          poNumber: cartPONumber,
          shippingMethod: shippingMethod,
          paymentMethod: paymentMethod,
          invoiceEmail: invoiceEmail,
          authorId: customer?.id,
          status:
            cart?.status == CartStatusEnum.UNSAVED
              ? CartStatusEnum.DRAFT
              : undefined,
          message: message,
          internalComment: internalMessage,
        });
      } else {
        if (
          shippingCity &&
          shippingCompany &&
          shippingFirstName &&
          shippingEmail &&
          shippingLastName &&
          shippingZipCode &&
          shippingAddressName &&
          shippingPhone &&
          shippingCvr
        ) {
          const shippingAddress: AddressType = {
            city: shippingCity,
            company: shippingCompany,
            countryId: "DK",
            firstName: shippingFirstName,
            email: shippingEmail,
            lastName: shippingLastName,
            postalCode: shippingZipCode,
            street: shippingAddressName,
            phone: shippingPhone,
            vat: shippingCvr,
          };

          return apiUpdateCartInfo(token, cartId, {
            billingAddress: billingAddress,
            shippingAddress: shippingAddress,
            sameAsBilling: useSameShipping ?? true,
            poNumber: cartPONumber,
            shippingMethod: shippingMethod,
            paymentMethod: paymentMethod,
            invoiceEmail: invoiceEmail,
            authorId: customer?.id,
            status:
              cart?.status == CartStatusEnum.UNSAVED
                ? CartStatusEnum.DRAFT
                : undefined,
            message: message,
            internalComment: internalMessage,
          });
        }
      }
    } else {
      return apiUpdateCartInfo(token, cartId, {
        poNumber: cartPONumber,
        shippingMethod: shippingMethod,
        paymentMethod: paymentMethod,
        invoiceEmail: invoiceEmail,
        authorId: customer?.id,
        status:
          cart?.status == CartStatusEnum.UNSAVED
            ? CartStatusEnum.DRAFT
            : undefined,
        message: message,
        internalComment: internalMessage,
      });
    }
  };

  const showSaveDraftSnack = () => {
    saveInfo()?.then((res) => {
      refreshCart();
      addToast({
        type: "success",
        message: "The order was saved as a draft.",
        keep: false,
      });
    });
  };

  const updateOrder = () => {
    if (!orderEditing) {
      return;
    }
    setShowLoader(true);
    return saveInfo()?.then((res) => {
      setCart(res);
      return apiRefreshOrderFromQuote(token, orderEditing.id, cartId).then(
        (res) => {
          localStorage.removeItem("orderEditing");
          return true;
        }
      );
    });
  };

  const confirmOrder = () => {
    if (inputErr) {
      addToast({
        type: "error",
        message: "Please fill in all required fields before saving.",
        keep: false,
      });
      return;
    }
    if (orderEditing !== undefined) {
      updateOrder()?.then((res) => {
        if (res) {
          setShowLoadingModal(false);
          setShowLoader(false);
          setShowSendEmailModal(true);
          addToast({
            type: "success",
            message: "The order was updated.",
            keep: false,
          });
        }
      });
    } else {
      if (
        customer?.id &&
        cartItems.length > 0 &&
        shippingMethod &&
        paymentMethod &&
        !inputErr
      ) {
        setShowLoader(true);
        saveInfo()?.then((res) => {
          setCart(res);
          return apiConfirmOrder(
            token,
            cartId,
            paymentMethod,
            EAN !== "" ? EAN : undefined
          )
            .then((res) => {
              // @ts-ignore
              window.gtag("event", "purchase", {
                transaction_id: res.id,
                currency: "DKK",
                shipping: res?.shipping,
                value: res?.total,
                items: res.items.map((item, key) => {
                  return {
                    item_id: item.product.id,
                    item_name: item.name,
                    affiliation: "EasyPartz",
                    currency: "DKK",
                    index: key,
                    price: item.unitPrice,
                    quantity: item.quantity,
                  };
                }),
              });
              // setCreateLoading(false);
              localStorage.removeItem("lastDraft");
              setShowLoadingModal(false);
              setShowLoader(false);
              addToast({
                type: "success",
                message: "The order was confirmed.",
                keep: false,
              });
              history.replace("/ordrestyring");
            })
            .catch((err) => {
              setShowLoadingModal(false);
              setShowLoader(false);
              addToast({
                type: "error",
                message: "An error has occured." + err.message,
                keep: true,
              });
            });
        });
      }
    }
  };

  const onAcceptAddressChange = () => {
    if (
      customer &&
      billingCity &&
      billingCompany &&
      billingFirstName &&
      billingEmail &&
      billingLastName &&
      billingZipCode &&
      billingAddressName &&
      billingPhone &&
      billingCvr
    ) {
      const billingAddress: AddressType = {
        city: billingCity,
        company: billingCompany,
        countryId: "DK",
        firstName: billingFirstName,
        email: billingEmail,
        lastName: billingLastName,
        postalCode: billingZipCode,
        street: billingAddressName,
        phone: billingPhone,
        vat: billingCvr,
      };
      let command: UserUpdateType = {
        firstName: customer?.firstName,
        lastName: customer?.lastName,
        billingAddress,
      };
      if (
        shippingCity &&
        shippingCompany &&
        shippingFirstName &&
        shippingEmail &&
        shippingLastName &&
        shippingZipCode &&
        shippingAddressName &&
        shippingPhone &&
        shippingCvr &&
        useSameShipping == false
      ) {
        const shippingAddress: AddressType = {
          city: shippingCity,
          company: shippingCompany,
          countryId: "DK",
          firstName: shippingFirstName,
          email: shippingEmail,
          lastName: shippingLastName,
          postalCode: shippingZipCode,
          street: shippingAddressName,
          phone: shippingPhone,
          vat: shippingCvr,
        };
        command.shippingAddress = shippingAddress;
      }
      apiUpdateUser(token, customer.id, command).then((res) => {
        confirmOrder();
      });
      addToast({
        type: "success",
        message: "New billing address was saved successfully.",
        keep: false,
      });
    }
  };

  const validateForm = () => {
    const errors = [];

    if (!customer?.id) errors.push("No customer selected");
    if (cartItems.length <= 0) errors.push("No items in the cart");
    if (!shippingMethod) errors.push("No shipping method selected");
    if (!paymentMethod) errors.push("No payment method selected");
    if (inputErr) errors.push("One or more required fields are empty");

    return errors;
  };

  const quoteAccept = () => {
    const errors = validateForm();

    if (errors.length > 0) {
      errors.forEach((error) => {
        addToast({
          type: "error",
          message: error,
          keep: false,
        });
      });
      setShowLoadingModal(false);
      setShowLoader(false);
      return;
    }

    confirmOrder();
  };

  const copyQuote = useCallback(() => {
    setShowLoader(true);
    apiCreateDraftCartFromCart(token, cartId).then((res) => {
      setShowLoadingModal(false);
      setShowLoader(false);
      addToast({
        type: "success",
        message: "Duplicate created.",
        keep: false,
      });
      history.push(`/quotes/${res.id}`);
    });
  }, [cartId, history, setShowLoadingModal, token]);

  const placeOrder = useCallback(() => {
    const errors = validateForm();

    if (errors.length > 0) {
      errors.forEach((error) => {
        addToast({
          type: "error",
          message: error,
          keep: false,
        });
      });
      setShowLoadingModal(false);
      setShowLoader(false);
      return;
    }
    if (
      billingCity !== customer?.defaultBillingAddress?.city ||
      billingZipCode !== customer?.defaultBillingAddress?.postalCode ||
      billingCompany !== customer?.defaultBillingAddress?.company ||
      billingCvr !== customer?.defaultBillingAddress?.vat ||
      billingFirstName !== customer?.defaultBillingAddress?.firstName ||
      billingLastName !== customer?.defaultBillingAddress?.lastName ||
      billingAddressName !== customer?.defaultBillingAddress?.street ||
      billingPhone !== customer?.defaultBillingAddress?.phone
    ) {
      setOpenSaveAddress(true);
    } else if (
      useSameShipping == false &&
      (shippingCity != customer?.defaultShippingAddress?.city ||
        shippingZipCode !== customer?.defaultShippingAddress?.postalCode ||
        shippingCompany !== customer?.defaultShippingAddress?.company ||
        shippingCvr !== customer?.defaultShippingAddress?.vat ||
        shippingFirstName !== customer?.defaultShippingAddress?.firstName ||
        shippingLastName !== customer?.defaultShippingAddress?.lastName ||
        shippingAddressName !== customer?.defaultShippingAddress?.street ||
        shippingPhone !== customer?.defaultShippingAddress?.phone)
    ) {
      setOpenSaveAddress(true);
    } else {
      confirmOrder();
    }
  }, [
    customer,
    history,
    id,
    saveInfo,
    token,
    orderEditing,
    cartItems,
    shippingMethod,
    paymentMethod,
    inputErr,
  ]);

  const feesValue = useMemo(() => {
    return feeItems.reduce((a, b) => a + b.total, 0);
  }, [feeItems]);

  const otherCartItemRows = useMemo(() => {
    return otherItems
      .sort((a, b) =>
        a.id && b.id ? (a.id > b.id ? 1 : a.id < b.id ? -1 : 0) : 0
      )
      .map((item: CartItemType, index: number) => {
        return (
          <OtherItemTableRow
            key={item.id}
            cartId={cartId}
            item={item}
            onChange={updateCartItem}
            deleteItem={removeCartItem}
            disabled={quoteAcceptMode || priceLoad}
          />
        );
      });
  }, [otherItems, updateCartItem, removeCartItem]);

  const cncCartItemRows = useMemo(() => {
    console.log("cncItems", cncItems);
    return cncItems
      .sort((a, b) => {
        if (a.name && b.name) {
          return a.name.localeCompare(b.name);
        }
        return 0;
      })
      .map((item: CartItemType, index: number) => (
        <CNCItemTableRow
          key={item.id}
          cartId={cartId}
          item={item}
          index={index}
          leadTime={leadtimeOption}
          onChange={updateCartItem}
          setCartLoading={setPriceLoad}
          deleteItem={() => removeCartItem(item)}
          isChecked={checkedItemsCnc[index]}
          onCheckedChange={handleCncChange}
          selectedMaterial={selectedMaterialCnc ?? customMaterialCnc ?? null}
          selectedFinish={selectedFinishCnc ?? customFinishCnc ?? null}
        />
      ));
  }, [
    cartId,
    cncItems.length,
    leadtimeOption,
    checkedItemsCnc,
    selectedMaterialCnc,
    selectedFinishCnc,
    customMaterialCnc,
    customFinishCnc,
    selectedRowsMaterialsCnc,
    selectedRowsFinishesCnc,
    finishDisplayCnc,
    materialDisplayCnc,
  ]);

  const print3DCartItemRows = useMemo(() => {
    return print3DItems
      .sort((a, b) => {
        if (a.name && b.name) {
          return a.name.localeCompare(b.name);
        }
        return 0;
      })
      .map((item: CartItemType, index: number) => (
        <Print3DItemTableRow
          key={item.id}
          cartId={cartId}
          item={item}
          index={index}
          leadTime={leadtimeOption}
          onChange={updateCartItem}
          setCartLoading={setPriceLoad}
          deleteItem={() => removeCartItem(item)}
          isChecked={checkedItemsPrint3D[index]}
          onCheckedChange={handlePrint3DChange}
          selectedMaterial={
            selectedMaterialPrint3D ?? customMaterialPrint3D ?? null
          }
          selectedFinish={selectedFinishPrint3D ?? customFinishPrint3D ?? null}
        />
      ));
  }, [
    cartId,
    print3DItems,
    leadtimeOption,
    checkedItemsPrint3D,
    selectedMaterialPrint3D,
    customMaterialPrint3D,
    selectedFinishPrint3D,
    customFinishPrint3D,
    selectedRowsMaterialsPrint3D,
    selectedRowsFinishesPrint3D,
  ]);

  const sheetCartItemRows = useMemo(() => {
    return sheetItems
      .sort((a, b) => {
        if (a.name && b.name) {
          return a.name.localeCompare(b.name);
        }
        return 0;
      })
      .map((item: CartItemType, index: number) => (
        <SheetItemTableRow
          key={item.id}
          cartId={cartId}
          item={item}
          index={index}
          leadTime={leadtimeOption}
          onChange={updateCartItem}
          setCartLoading={setPriceLoad}
          deleteItem={() => removeCartItem(item)}
          isChecked={checkedItemsSheet[index]}
          onCheckedChange={handleSheetChange}
          selectedMaterial={
            selectedMaterialSheet ?? customMaterialSheet ?? null
          }
          selectedFinish={selectedFinishSheet ?? customFinishSheet ?? null}
        />
      ));
  }, [
    cartId,
    sheetItems,
    leadtimeOption,
    checkedItemsSheet,
    selectedMaterialSheet,
    customMaterialSheet,
    selectedFinishSheet,
    customFinishSheet,
  ]);

  const feeCartItemRows = useMemo(() => {
    return feeItems
      .sort((a, b) => (a.name > b.name ? 1 : a.name < b.name ? -1 : 0))
      .map((item: CartItemType) => (
        <FeeItemTableRow
          key={item.id}
          cartId={cartId}
          item={item}
          leadTime={leadtimeOption}
          onChange={updateCartItem}
          deleteItem={removeCartItem}
        />
      ));
  }, [feeItems, updateCartItem, removeCartItem, leadtimeOption]);

  const [confirmModalLabel, setConfirmModalLabel] = useState<string>("");
  const [confirmModalAction, setConfirmModalAction] = useState<Function>();

  const dropdownOptions = useMemo(() => {
    const validateAndShowErrors = () => {
      const errors = validateForm();
      setErrorNames(errors);
      if (errors.length > 0) {
        errors.forEach((error) => {
          addToast({
            type: "error",
            message: error,
            keep: false,
          });
        });
        return false;
      }
      return true;
    };
    if (quoteAcceptMode) {
      return [
        {
          label: "Order",
          onClick: (e: any) => {
            setConfirmModalLabel("Are you sure you want to order this quote?");
            setConfirmModalAction(() => {
              return quoteAccept;
            });
          },
        },
        {
          label: "Draft",
          onClick: (e: any) => {
            setConfirmModalLabel(
              "Are you sure you want to convert this quote to a draft?"
            );
            setConfirmModalAction(() => {
              return () => updateQuoteStatus(CartStatusEnum.DRAFT);
            });
          },
        },
        {
          label: "Duplicate",
          onClick: (e: any) => {
            setConfirmModalLabel(
              "Are you sure you want to duplicate this quote?"
            );
            setConfirmModalAction(() => {
              return copyQuote;
            });
          },
        },
        {
          label: "Mark Lost",
          onClick: (e: any) => {
            setConfirmModalLabel(
              "Are you sure you want to mark this quote as lost?"
            );
            setConfirmModalAction(() => {
              return () => updateQuoteStatus(CartStatusEnum.LOST);
            });
          },
        },
      ];
    } else if (reviewMode) {
      return [
        {
          label: "Quote",
          onClick: (e: any) => {
            setConfirmModalLabel(
              "Are you sure you want to convert this draft to a quote?"
            );
            setConfirmModalAction(() => {
              return () => {
                if (inputErr) {
                  errorNames.push("One or more required fields are empty");
                  return;
                }
                if (!shippingMethod) {
                  errorNames.push("Please choose a shipping method");
                  return;
                }
                setShowLoader(true);
                saveInfo()?.then((res) => {
                  apiLockCart(token, cartId).then((res) => {
                    refreshCart();
                    setShowLoadingModal(false);
                    setShowLoader(false);
                    addToast({
                      type: "success",
                      message: "The cart was converted to a quote.",
                      keep: false,
                    });
                  });
                });
              };
            });
          },
        },
        {
          label: "Draft",
          onClick: (e: any) => {
            setConfirmModalLabel(
              "Are you sure you want to convert this quote to a draft?"
            );
            setConfirmModalAction(() => {
              return () => updateQuoteStatus(CartStatusEnum.DRAFT);
            });
          },
        },
        {
          label: "Order",
          onClick: (e: any) => {
            setConfirmModalLabel("Are you sure you want to order this quote?");
            setConfirmModalAction(() => {
              return quoteAccept;
            });
          },
        },
        {
          label: "Duplicate",
          onClick: (e: any) => {
            setConfirmModalLabel(
              "Are you sure you want to duplicate this quote?"
            );
            setConfirmModalAction(() => {
              return copyQuote;
            });
          },
        },
        {
          label: "Mark Lost",
          onClick: (e: any) => {
            setConfirmModalLabel(
              "Are you sure you want to mark this quote as lost?"
            );
            setConfirmModalAction(() => {
              return () => updateQuoteStatus(CartStatusEnum.LOST);
            });
          },
        },
      ];
    } else {
      return [
        {
          label: "Order",
          onClick: (e: any) => {
            setConfirmModalLabel("Are you sure you want to order this draft?");
            setConfirmModalAction(() => {
              return placeOrder;
            });
          },
        },
        {
          label: "Quote",
          onClick: (e: any) => {
            setConfirmModalLabel(
              "Are you sure you want to convert this draft to a quote?"
            );
            setConfirmModalAction(() => {
              return () => {
                if (!validateAndShowErrors()) return;

                setShowLoader(true);
                saveInfo()?.then((res) => {
                  apiLockCart(token, cartId).then((res) => {
                    refreshCart();
                    setShowLoadingModal(false);
                    setShowLoader(false);
                    addToast({
                      type: "success",
                      message: "The cart was converted to a quote.",
                      keep: false,
                    });
                  });
                });
              };
            });
          },
        },
        {
          label: "Duplicate",
          onClick: (e: any) => {
            setConfirmModalLabel(
              "Are you sure you want to duplicate this quote?"
            );
            setConfirmModalAction(() => {
              return copyQuote;
            });
          },
        },
        {
          label: "Mark Lost",
          onClick: (e: any) => {
            setConfirmModalLabel(
              "Are you sure you want to mark this quote as lost?"
            );
            setConfirmModalAction(() => {
              return () => updateQuoteStatus(CartStatusEnum.LOST);
            });
          },
        },
      ];
    }
  }, [
    cartId,
    copyQuote,
    errorNames,
    inputErr,
    placeOrder,
    quoteAccept,
    quoteAcceptMode,
    refreshCart,
    reviewMode,
    setShowLoadingModal,
    token,
    updateQuoteStatus,
    shippingMethod,
  ]);

  const createDummyProduct = (name: string, type: ProductTypeEnum) => {
    setShowLoader(true);
    apiCreateModel(token, type, undefined, name)
      .then((res) => {
        if (type == ProductTypeEnum.CNC) {
          const command: CreateCNCProductType = {
            modelId: res.filesList[0].id,
            quantity: 1,
            material: "alu-6082",
            finish: ["standard"],
            blueprint: null,
            comment: null,
            threads: [],
            productType: ProductTypeEnum.CNC,
          };
          return apiCreateProduct(token, command).then((res) => {
            addCartItem(res.id, 1);
          });
        } else if (type == ProductTypeEnum.PRINT3D) {
          const command: CreatePrint3DProductType = {
            modelId: res.filesList[0].id,
            quantity: 1,
            material: "MDP-120",
            finish: ["standard"],
            comment: null,
            threads: [],
            productType: ProductTypeEnum.PRINT3D,
          };
          return apiCreateProduct(token, command).then((res) => {
            addCartItem(res.id, 1);
          });
        } else if (type == ProductTypeEnum.SHEET) {
          const command: CreateSheetProductType = {
            modelId: res.filesList[0].id,
            quantity: 1,
            material: "alu-5754",
            finish: ["standard"],
            blueprint: null,
            comment: null,
            threads: [],
            productType: ProductTypeEnum.SHEET,
          };
          return apiCreateProduct(token, command).then((res) => {
            addCartItem(res.id, 1);
          });
        }
      })
      .then(() => {
        setShowLoader(false);
        setShowDummyProductModal(false);
      })
      .catch((e) => {
        setShowLoader(false);
        addToast({
          type: "error",
          message: "An error has occured." + e,
          keep: true,
        });
      });
  };

  return (
    <Container className={styles.mrgTop} maxWidth={false}>
      <DummyProductModal
        isOpen={showDummyProductModal}
        onClose={() => {
          setShowDummyProductModal(false);
        }}
        onSubmit={createDummyProduct}
      />
      <LoadingModal show={showLoader} />
      <Prompt
        when={cart?.status === CartStatusEnum.UNSAVED}
        message="You have unsaved changes in your cart. Are you sure you want to leave?"
      />
      <Modal
        isOpen={confirmModalAction !== undefined}
        onClose={() => setConfirmModalAction(undefined)}
      >
        <Typography variant="h4" className={styles.txAlgn}>
          Confirm
        </Typography>
        <Typography variant="body1" className={styles.txAlgn}>
          {confirmModalLabel}
        </Typography>
        <Box className={styles.containerBtn}>
          <Button
            variant="contained"
            color="error"
            className={styles.modalCancelBtn}
            onClick={() => {
              setConfirmModalAction(undefined);
            }}
          >
            <Cancel /> {<h5 className={styles.mrgLeft}>No</h5>}
          </Button>
          <Button
            variant="contained"
            color="success"
            className={styles.modalAcceptBtn}
            onClick={() => {
              confirmModalAction && confirmModalAction();
              setConfirmModalAction(undefined);
            }}
          >
            <Check /> {<h5 className={styles.mrgLeft}>Yes</h5>}
          </Button>
        </Box>
      </Modal>

      <Modal
        isOpen={showSendEmailModal}
        onClose={() => setShowSendEmailModal(false)}
      >
        <Typography variant="h4" className={styles.txAlgn}>
          Send order confirmation
        </Typography>
        <Typography variant="body1" className={styles.txAlgn}>
          Send a mail to the customer with the order confirmation?
        </Typography>
        <Box className={styles.containerBtn}>
          <Button
            variant="contained"
            color="error"
            className={styles.modalCancelBtn}
            onClick={() => {
              if (orderEditing) {
                setShowSendEmailModal(false);
                history.replace(`/ordrestyring/${orderEditing.id}`);
              }
            }}
          >
            <Cancel /> {<h5 className={styles.mrgLeft}>No</h5>}
          </Button>
          <Button
            variant="contained"
            color="primary"
            className={styles.modalCancelBtn}
            onClick={() => {
              if (orderEditing) {
                apiSendOrderEmail(token, orderEditing.id).then((emailSent) => {
                  setShowSendEmailModal(false);
                  history.replace(`/ordrestyring/${orderEditing.id}`);
                });
              }
            }}
          >
            <Check /> {<h5 className={styles.mrgLeft}>Yes</h5>}
          </Button>
        </Box>
      </Modal>

      {orderEditing && (
        <Typography className={styles.draftTitle}>
          Editing order: {orderEditing.orderNo}
        </Typography>
      )}
      <Typography className={styles.draftTitle}>
        Status:{" "}
        {cart?.status === CartStatusEnum.LOCKED ? "QUOTE" : cart?.status}
      </Typography>
      <div className={styles.draftBtnContainer}>
        <div className={styles.flexR}>
          <div className={styles.pdRight50}>
            <Button
              onClick={() => handleGoBack()}
              className={styles.draftBackBtn}
            >
              <Cancel /> {<h5 className={styles.mrgLeft}>BACK</h5>}
            </Button>
          </div>
          <div className={styles.pdRight50}>
            <Button
              className={styles.draftSaveBtn}
              //disabled={inputErr || items.length <= 0 || !customer}
              onClick={orderEditing ? confirmOrder : showSaveDraftSnack}
            >
              {<Save />}
              {orderEditing ? (
                <h5 className={styles.mrgLeft}>{"UPDATE ORDER"}</h5>
              ) : (
                <h5 className={styles.mrgLeft}>{"SAVE DRAFT"}</h5>
              )}
            </Button>
          </div>
          <div className={styles.pdRight50}>
            {orderEditing ? (
              ""
            ) : (
              <BasicDropdownButton
                options={dropdownOptions}
                disabled={!customer}
              />
            )}
          </div>
        </div>
        {openSaveAddress && (
          <SaveBillingAddressDialog
            isOpen={openSaveAddress}
            setIsOpen={setOpenSaveAddress}
            onAccept={onAcceptAddressChange}
            onCancel={confirmOrder}
          />
        )}
      </div>
      <Grid container direction="row" justifyContent="start">
        <Grid item>
          <UserModal
            setCustomer={(cust) => {
              setCustomer(cust);
              addToast({
                type: "success",
                message: "Customer updated",
              });
            }}
          />
          <AccountInfoTable
            customer={customer}
            setCustomer={(cust) => {
              setCustomer(cust);
              addToast({
                type: "success",
                message: "Customer updated",
              });
            }}
            disabled={
              cart?.author !== undefined &&
              cart?.author !== null &&
              ((cart?.status !== CartStatusEnum.DRAFT &&
                cart?.status !== CartStatusEnum.UNSAVED) ||
                orderEditing !== undefined)
            }
          />
        </Grid>

        <Grid item xs={12} md={12}>
          <hr className={styles.headerBcg} />
        </Grid>

        <Grid xs={12} md={12} item>
          <div className={styles.flexR}>
            <div className={styles.priorityContainer}>
              <FormControl
                sx={{ minWidth: 120 }}
                size="small"
                disabled={quoteAcceptMode}
              >
                <InputLabel>Priority</InputLabel>
                <Select
                  value={leadtimeOption}
                  label="Priority"
                  onChange={(event) =>
                    changeLeadTime(event.target.value as LeadOptionsType)
                  }
                >
                  <MenuItem value={"EXPRESS"}>EXPRESS</MenuItem>
                  <MenuItem value={"STANDARD"}>STANDARD</MenuItem>
                  <MenuItem value={"ECONOMIC"}>ECONOMIC</MenuItem>
                  <MenuItem value={"CUSTOM"}>CUSTOM</MenuItem>
                </Select>
              </FormControl>
              <TextField
                label="Last workday"
                InputLabelProps={{
                  shrink: true,
                }}
                type={"date"}
                size="small"
                value={leadTimeDate}
                onChange={(e) => {
                  changeLeadTimeDate(e.target.value);
                }}
                disabled={quoteAcceptMode || leadtimeOption !== "CUSTOM"}
              ></TextField>
              <Button
                variant="contained"
                onClick={() => {
                  setShowDummyProductModal(true);
                }}
              >
                Add dummy product
              </Button>
            </div>
          </div>

          <Table size="small" aria-label="a dense table">
            <TableHead>
              <TableRow className={`${styles.headerBcg} ${styles.pdRmv}`}>
                <TableCell colSpan={10} className={styles.tdContainer}>
                  <div className={styles.tdBtnContainer}>
                    <p>CNC</p>
                    {/* <button onClick={handleSortByName}>
                      {isSortedByName ? "Unsort by Name" : "Sort by Name"}
                    </button> */}
                    <DraggableButton
                      accept={".step, .STEP, .stp, .STP"}
                      buttonProps={{
                        sx: {
                          backgroundColor: "var(--buttonYellow)",
                        },
                      }}
                      onFilesDropped={(files) => {
                        files.forEach((file) => {
                          addDraftItemFromModel(file, ProductTypeEnum.CNC);
                        });
                      }}
                    >
                      <b>+ CNC</b>
                    </DraggableButton>
                  </div>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={styles.pdRmv}>
                  <div className={styles.checkboxContainer}>
                    <Tooltip title={"Select All"}>
                      <Checkbox
                        checked={selectAllCheckCnc}
                        onChange={handleSelectAllCnc}
                      />
                    </Tooltip>
                    {`\u00A0`}
                    <span>Id</span>
                  </div>
                </TableCell>
                <TableCell align="center">Previews</TableCell>
                <TableCell align="left" colSpan={2}>
                  Name
                </TableCell>
                <TableCell align="left">
                  {customMaterialCnc !== undefined ? (
                    <CustomMaterialSelector
                      value={customMaterialCnc}
                      disabled={!selectAllCheckCnc}
                      onChange={(val: CustomMaterialType | null) => {
                        checkedItemsCnc.forEach((isChecked, index) => {
                          const itemToUpdate = cncItems[index];
                          if (
                            isChecked &&
                            itemToUpdate.product.productType ===
                              ProductTypeEnum.CNC
                          ) {
                            let command: UpdateCNCProductType;

                            if (val) {
                              setCustomMaterialCnc(val);
                              setSelectedMaterialCnc(null);
                              command = {
                                customMaterial: val,
                              };
                            } else {
                              setCustomMaterialCnc(undefined);
                              setSelectedMaterialCnc("alu-6082");

                              command = {
                                finish: ["standard"],
                                material: "alu-6082",
                              };
                            }

                            apiUpdateProduct(
                              token,
                              itemToUpdate.product.id,
                              command
                            ).then((res) => {
                              updateCartItem(itemToUpdate);
                            });
                          }
                        });
                      }}
                    />
                  ) : (
                    <MatSelector
                      materialFile={"cncMaterials.json"}
                      key={materialDisplayCnc}
                      currentSel={
                        selectedMaterialCnc || customMaterialCnc || "Material"
                      }
                      disabled={!selectAllCheckCnc}
                      onChange={(mat: string) => {
                        setSelectedMaterialCnc(mat);
                        if (mat === "custom") {
                          setCustomMaterialCnc({
                            name: "",
                            description: "",
                            customerProvides: false,
                            density: 0,
                          });
                        } else {
                          setCustomMaterialCnc(undefined);
                          checkedItemsCnc.forEach((isChecked, index) => {
                            if (isChecked) {
                              const itemToUpdate = cncItems[index];

                              const command: UpdateCNCProductType = {
                                finish: ["standard"],
                                material:
                                  typeof mat === "string" ? mat : undefined,
                              };

                              apiUpdateProduct(
                                token,
                                itemToUpdate.product.id,
                                command
                              ).then(() => {
                                updateCartItem(itemToUpdate);
                              });
                            }
                          });
                        }
                      }}
                    />
                  )}
                </TableCell>
                <TableCell align="center">
                  {customFinishCnc !== undefined ? (
                    <CustomFinishSelector
                      value={customFinishCnc}
                      onChange={(custom) => {
                        if (custom === null) {
                          setSelectedFinishCnc(["standard"]);
                          setCustomFinishCnc(undefined);
                        } else {
                          setCustomFinishCnc(custom);
                          setSelectedFinishCnc(null);

                          checkedItemsCnc.forEach((isChecked, index) => {
                            if (isChecked) {
                              const itemToUpdate = cncItems[index];
                              const command = { customFinish: custom };

                              apiUpdateProduct(
                                token,
                                itemToUpdate.product.id,
                                command
                              )
                                .then(() => {
                                  updateCartItem(itemToUpdate);
                                })
                                .catch((error) => {
                                  console.error(
                                    "Error updating product:",
                                    error
                                  );
                                });
                            }
                          });
                        }
                      }}
                    />
                  ) : (
                    <FinishSelector
                      material={selectedMaterialCnc || "alu-6082"}
                      finishFile={"cncFinish.json"}
                      currentSel={selectedFinishCnc ?? ["standard"]}
                      disabled={
                        (!selectedMaterialCnc && !customMaterialCnc) ||
                        !selectAllCheckCnc
                      }
                      onChange={(selectedFinishes: string[]) => {
                        setSelectedFinishCnc(selectedFinishCnc);
                        if (selectedFinishes.includes("custom")) {
                          setCustomFinishCnc({
                            name: "",
                            description: "",
                          });

                          return;
                        } else {
                          checkedItemsCnc.forEach((isChecked, index) => {
                            if (isChecked) {
                              const itemToUpdate = cncItems[index];

                              const command: UpdateCNCProductType = {
                                finish: selectedFinishes,
                              };

                              apiUpdateProduct(
                                token,
                                itemToUpdate.product.id,
                                command
                              ).then(() => {
                                updateCartItem(itemToUpdate);
                              });
                            }
                          });
                        }
                      }}
                    />
                  )}
                </TableCell>
                <TableCell align="center">Qty</TableCell>
                <TableCell>Unit Price</TableCell>
                <TableCell align="left" colSpan={2}>
                  Total
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {cncCartItemRows}
              {uploadingFiles.length > 0 &&
                uploadingFiles
                  .filter(
                    (file) => file.tech === ProductTypeEnum.CNC && file.loading
                  )
                  .map((file, index) => (
                    <tr key={index}>
                      <td></td>
                      <td className={styles.loaderTd}>
                        <div className={styles.loader}>
                          <CircularProgress />
                        </div>
                      </td>
                      <td className={styles.loadingFileName}>{file.name}...</td>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>
                  ))}
            </TableBody>
            <TableHead>
              <TableRow className={styles.headerBcg}>
                <TableCell colSpan={10} className={styles.tdContainer}>
                  <div className={styles.tdBtnContainer}>
                    <p>Print3D</p>

                    <DraggableButton
                      accept={".step, .STEP, .stp, .STP"}
                      buttonProps={{
                        sx: {
                          backgroundColor: "var(--buttonYellow)",
                        },
                      }}
                      onFilesDropped={(files) => {
                        files.forEach((file) => {
                          addDraftItemFromModel(file, ProductTypeEnum.PRINT3D);
                        });
                      }}
                    >
                      <b>+ PRINT3D</b>
                    </DraggableButton>
                  </div>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={styles.pdRmv}>
                  <div className={styles.checkboxContainer}>
                    <Tooltip title={"Select All"}>
                      <Checkbox
                        checked={selectAllCheckPrint3D}
                        onChange={handleSelectAllPrint3D}
                      />
                    </Tooltip>
                    {`\u00A0`}
                    <span>Id</span>
                  </div>
                </TableCell>
                <TableCell align="center">Previews</TableCell>
                <TableCell align="left" colSpan={2}>
                  Name
                </TableCell>
                <TableCell align="left">
                  {customMaterialPrint3D !== undefined ? (
                    <CustomMaterialSelector
                      disabled={!selectAllCheckPrint3D}
                      value={customMaterialPrint3D}
                      onChange={(val: CustomMaterialType | null) => {
                        checkedItemsPrint3D.forEach((isChecked, index) => {
                          const itemToUpdate = print3DItems[index];
                          if (
                            isChecked &&
                            itemToUpdate.product.productType ===
                              ProductTypeEnum.PRINT3D
                          ) {
                            let command: UpdatePrint3DProductType;

                            if (val) {
                              setCustomMaterialPrint3D(val);
                              setSelectedMaterialPrint3D(null);
                              command = {
                                customMaterial: val,
                              };
                            } else {
                              setCustomMaterialPrint3D(undefined);
                              setSelectedMaterialPrint3D("MDP-120");
                              command = {
                                finish: ["standard"],
                                material: "MDP-120",
                              };
                            }

                            apiUpdateProduct(
                              token,
                              itemToUpdate.product.id,
                              command
                            ).then((res) => {
                              updateCartItem(itemToUpdate);
                            });
                          }
                        });
                      }}
                    />
                  ) : (
                    <MatSelector
                      materialFile={"print3DMaterials.json"}
                      currentSel={
                        print3DMaterialValue ||
                        customMaterialPrint3D ||
                        "Material"
                      }
                      disabled={!selectAllCheckPrint3D}
                      onChange={(mat: string) => {
                        setSelectedMaterialPrint3D(mat);
                        if (mat === "custom") {
                          setCustomMaterialPrint3D({
                            name: "",
                            description: "",
                            customerProvides: false,
                            density: 0,
                          });
                        } else {
                          setCustomMaterialPrint3D(undefined);
                          checkedItemsPrint3D.forEach((isChecked, index) => {
                            if (isChecked) {
                              const itemToUpdate = print3DItems[index];

                              const command: UpdatePrint3DProductType = {
                                finish: ["standard"],
                                material:
                                  typeof mat === "string" ? mat : undefined,
                              };

                              apiUpdateProduct(
                                token,
                                itemToUpdate.product.id,
                                command
                              ).then((res) => {
                                updateCartItem(itemToUpdate);
                              });
                            }
                          });
                        }
                      }}
                    />
                  )}
                </TableCell>
                <TableCell align="center">
                  {customFinishPrint3D !== undefined ? (
                    <CustomFinishSelector
                      value={customFinishPrint3D}
                      onChange={(custom: CustomFinishType | null) => {
                        if (custom === null) {
                          setSelectedFinishPrint3D(["standard"]);
                          setCustomFinishPrint3D(undefined);
                        } else {
                          setCustomFinishPrint3D(custom);
                          setSelectedFinishPrint3D(null);
                          checkedItemsPrint3D.forEach((isChecked, index) => {
                            if (isChecked) {
                              const itemToUpdate = print3DItems[index];

                              const command: UpdatePrint3DProductType = {
                                customFinish: custom,
                              };

                              apiUpdateProduct(
                                token,
                                itemToUpdate.product.id,
                                command
                              ).then(() => {
                                updateCartItem(itemToUpdate);
                              });
                            }
                          });
                        }
                      }}
                    />
                  ) : (
                    <FinishSelector
                      material={selectedMaterialPrint3D || "MDP-120"}
                      finishFile={"print3DFinish.json"}
                      currentSel={selectedFinishPrint3D ?? ["standard"]}
                      disabled={
                        (!selectedMaterialPrint3D && !customMaterialPrint3D) ||
                        !selectAllCheckPrint3D
                      }
                      onChange={(selectedFinishes: string[]) => {
                        setSelectedFinishPrint3D(selectedFinishPrint3D);
                        if (selectedFinishes.includes("custom")) {
                          setCustomFinishPrint3D({
                            name: "",
                            description: "",
                          });

                          return;
                        } else {
                          checkedItemsPrint3D.forEach((isChecked, index) => {
                            if (isChecked) {
                              const itemToUpdate = print3DItems[index];

                              const command: UpdatePrint3DProductType = {
                                finish: selectedFinishes,
                              };

                              apiUpdateProduct(
                                token,
                                itemToUpdate.product.id,
                                command
                              ).then(() => {
                                updateCartItem(itemToUpdate);
                              });
                            }
                          });
                        }
                      }}
                    />
                  )}
                </TableCell>
                <TableCell align="center">Qty</TableCell>
                <TableCell>Unit Price</TableCell>
                <TableCell align="left" colSpan={2}>
                  Total
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {print3DCartItemRows}
              {uploadingFiles.length > 0 &&
                uploadingFiles
                  .filter(
                    (file) =>
                      file.tech === ProductTypeEnum.PRINT3D && file.loading
                  )
                  .map((file, index) => (
                    <tr key={index}>
                      <td></td>
                      <td className={styles.loaderTd}>
                        <div className={styles.loader}>
                          <CircularProgress />
                        </div>
                      </td>
                      <td className={styles.loadingFileName}>{file.name}...</td>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>
                  ))}
            </TableBody>
            <TableHead sx={{ color: "info" }}>
              <TableRow className={styles.headerBcg}>
                <TableCell colSpan={10} className={styles.tdContainer}>
                  <div className={styles.tdBtnContainer}>
                    <p>Sheet</p>
                    <DraggableButton
                      accept={".step, .STEP, .stp, .STP"}
                      buttonProps={{
                        sx: {
                          backgroundColor: "var(--buttonYellow)",
                        },
                      }}
                      onFilesDropped={(files: File[]) => {
                        files.forEach((file) => {
                          addDraftItemFromModel(file, ProductTypeEnum.SHEET);
                        });
                      }}
                    >
                      <b>+ SHEET</b>
                    </DraggableButton>
                  </div>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={styles.pdRmv}>
                  <div className={styles.checkboxContainer}>
                    <Tooltip title={"Select All"}>
                      <Checkbox
                        checked={selectAllCheckSheet}
                        onChange={handleSelectAllSheet}
                      />
                    </Tooltip>
                    {`\u00A0`}
                    <span>Id</span>
                  </div>
                </TableCell>
                <TableCell align="center">Previews</TableCell>
                <TableCell align="left" colSpan={2}>
                  Name
                </TableCell>
                <TableCell align="left">
                  {customMaterialSheet !== undefined ? (
                    <CustomMaterialSelector
                      value={customMaterialSheet}
                      disabled={!selectAllCheckSheet}
                      onChange={(val: CustomMaterialType | null) => {
                        checkedItemsSheet.forEach((isChecked, index) => {
                          const itemToUpdate = sheetItems[index];
                          if (
                            isChecked &&
                            itemToUpdate.product.productType ===
                              ProductTypeEnum.SHEET
                          ) {
                            let command: UpdateSheetProductType;

                            if (val) {
                              setCustomMaterialSheet(val);
                              setSelectedMaterialSheet(null);
                              command = {
                                customMaterial: val,
                              };
                            } else {
                              setCustomMaterialSheet(undefined);
                              setSelectedMaterialSheet("alu-5754");

                              command = {
                                finish: ["standard"],
                                material: "alu-5754",
                              };
                            }

                            apiUpdateProduct(
                              token,
                              itemToUpdate.product.id,
                              command
                            ).then((res) => {
                              updateCartItem(itemToUpdate);
                            });
                          }
                        });
                      }}
                    />
                  ) : (
                    <MatSelector
                      disabled={!selectAllCheckSheet}
                      materialFile={"sheetMaterials.json"}
                      // key={materialDisplaySheet}
                      currentSel={
                        selectedMaterialSheet ||
                        customMaterialSheet ||
                        "Material"
                      }
                      onChange={(mat: string) => {
                        setSelectedMaterialSheet(mat);
                        if (mat === "custom") {
                          setCustomMaterialSheet({
                            name: "",
                            description: "",
                            customerProvides: false,
                            density: 0,
                          });
                        } else {
                          setCustomMaterialSheet(undefined);
                          checkedItemsSheet.forEach((isChecked, index) => {
                            if (isChecked) {
                              const itemToUpdate = sheetItems[index];

                              const command: UpdateSheetProductType = {
                                finish: ["standard"],
                                material:
                                  typeof mat === "string" ? mat : undefined,
                              };

                              apiUpdateProduct(
                                token,
                                itemToUpdate.product.id,
                                command
                              ).then(() => {
                                updateCartItem(itemToUpdate);
                              });
                            }
                          });
                        }
                      }}
                    />
                  )}
                </TableCell>
                <TableCell align="center">
                  {" "}
                  {customFinishSheet !== undefined ? (
                    <CustomFinishSelector
                      value={customFinishSheet}
                      onChange={(custom: CustomFinishType | null) => {
                        if (custom === null) {
                          setSelectedFinishSheet(["standard"]);
                          setCustomFinishSheet(undefined);
                        } else {
                          setCustomFinishSheet(custom);
                          setSelectedFinishSheet(null);
                          checkedItemsSheet.forEach((isChecked, index) => {
                            if (isChecked) {
                              const itemToUpdate = sheetItems[index];

                              const command: UpdateSheetProductType = {
                                customFinish: custom,
                              };

                              apiUpdateProduct(
                                token,
                                itemToUpdate.product.id,
                                command
                              ).then(() => {
                                updateCartItem(itemToUpdate);
                              });
                            }
                          });
                        }
                      }}
                    />
                  ) : (
                    <FinishSelector
                      material={selectedMaterialSheet || "alu-5754"}
                      finishFile={"sheetFinish.json"}
                      currentSel={selectedFinishSheet ?? ["standard"]}
                      disabled={
                        (!selectedMaterialSheet && !customMaterialSheet) ||
                        !selectAllCheckSheet
                      }
                      onChange={(selectedFinishes: string[]) => {
                        setSelectedFinishSheet(selectedFinishSheet);
                        if (selectedFinishes.includes("custom")) {
                          setCustomFinishSheet({
                            name: "",
                            description: "",
                          });

                          return;
                        } else {
                          checkedItemsSheet.forEach((isChecked, index) => {
                            if (isChecked) {
                              const itemToUpdate = sheetItems[index];

                              const command: UpdateSheetProductType = {
                                finish: selectedFinishes,
                              };

                              apiUpdateProduct(
                                token,
                                itemToUpdate.product.id,
                                command
                              ).then(() => {
                                updateCartItem(itemToUpdate);
                              });
                            }
                          });
                        }
                      }}
                    />
                  )}
                </TableCell>
                <TableCell align="center">Qty</TableCell>
                <TableCell>Unit Price</TableCell>
                <TableCell align="left" colSpan={2}>
                  Total
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {sheetCartItemRows}
              {uploadingFiles.length > 0 &&
                uploadingFiles
                  .filter(
                    (file) =>
                      file.tech === ProductTypeEnum.SHEET && file.loading
                  )
                  .map((file, index) => (
                    <tr key={index}>
                      <td></td>
                      <td className={styles.loaderTd}>
                        <div className={styles.loader}>
                          <CircularProgress />
                        </div>
                      </td>
                      <td className={styles.loadingFileName}>{file.name}...</td>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>
                  ))}
            </TableBody>
            <TableHead>
              <TableRow className={styles.headerBcg}>
                <TableCell colSpan={10} className={styles.clrWhite}>
                  <span className={styles.clrWhite}>Other</span>
                  <span className={styles.pdLeft1}>
                    <CreateOtherProductModal
                      onSubmit={(res) => {
                        addCartItem(res.id);
                      }}
                      onCancel={() => console.log("Cancelled")}
                      // disabled={quoteAcceptMode}
                    />
                  </span>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={2}>Name</TableCell>
                <TableCell colSpan={3}>Description</TableCell>
                <TableCell colSpan={3}>Price</TableCell>
                <TableCell colSpan={2} align="center">
                  Files
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>{otherCartItemRows}</TableBody>
            <TableHead>
              <TableRow className={styles.headerBcg}>
                <TableCell colSpan={10} className={styles.clrWhite}>
                  <span className={styles.clrWhite}>Fees</span>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={2}>Name</TableCell>
                <TableCell></TableCell>
                <TableCell colSpan={4}>Description</TableCell>
                <TableCell colSpan={2}>Price</TableCell>
                <TableCell colSpan={2}></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>{feeCartItemRows}</TableBody>
          </Table>
        </Grid>
        <Grid xs={12} md={12} item></Grid>
        <br />
        <Grid item xs md>
          <div style={{ flex: 1 }}>
            <AmountTables
              total={cartTotals?.total ?? 0}
              totalTax={cartTotals?.totalTax ?? 0}
              fees={feesValue ?? 0}
            />
          </div>
        </Grid>

        <Grid container direction={"row"} spacing={"1em"}>
          <Grid item xs={4}>
            <AddressFields
              shippingCompany={shippingCompany ?? ""}
              shippingCvr={shippingCvr ?? ""}
              shippingFirstName={shippingFirstName ?? ""}
              shippingLastName={shippingLastName ?? ""}
              shippingAddressName={shippingAddressName ?? ""}
              shippingZipCode={shippingZipCode ?? ""}
              shippingCity={shippingCity ?? ""}
              shippingPhone={shippingPhone ?? ""}
              shippingEmail={shippingEmail ?? ""}
              billingCompany={billingCompany ?? ""}
              billingCvr={billingCvr ?? ""}
              billingFirstName={billingFirstName ?? ""}
              billingLastName={billingLastName ?? ""}
              billingAddressName={billingAddressName ?? ""}
              billingZipCode={billingZipCode ?? ""}
              billingCity={billingCity ?? ""}
              billingPhone={billingPhone ?? ""}
              billingEmail={billingEmail ?? ""}
              invoiceEmail={invoiceEmail ?? ""}
              setShippingCompany={setShippingCompany}
              setShippingCvr={setShippingCvr}
              setShippingFirstName={setShippingFirstName}
              setShippingLastName={setShippingLastName}
              setShippingAddressName={setShippingAddressName}
              setShippingZipCode={setShippingZipCode}
              setShippingCity={setShippingCity}
              setShippingPhone={setShippingPhone}
              setShippingEmail={setShippingEmail}
              setBillingCompany={setBillingCompany}
              setBillingCvr={setBillingCvr}
              setBillingFirstName={setBillingFirstName}
              setBillingLastName={setBillingLastName}
              setBillingAddressName={setBillingAddressName}
              setBillingZipCode={setBillingZipCode}
              setBillingCity={setBillingCity}
              setBillingPhone={setBillingPhone}
              setBillingEmail={setBillingEmail}
              setInvoiceEmail={setInvoiceEmail}
              poNumber={cartPONumber}
              setPoNumber={setCartPONumber}
              EANNumber={EAN}
              setEANNumber={setEAN}
              useSameShipping={useSameShipping}
              setUseSameShipping={setUseSameShipping}
              inputErr={inputErr}
              setInputErr={setInputErr}
              paymentMethod={paymentMethod}
              fillAddressFields={fillAddressFields}
            />
          </Grid>
          <Grid item xs={4}>
            <div className={styles.flexClm}>
              <ShippingMethods
                shippingMethods={shippingMethods}
                updateShipping={updateShippingMethod}
                currentMethod={shippingMethod}
                isCustomShippingMethod={isCustomShippingMethod}
              />
              <PaymentMethods
                paymentMethod={paymentMethod}
                setPaymentMethod={setPaymentMethod}
                disabled={saveLoad}
              />
            </div>
          </Grid>
          <Grid item xs={4}>
            <div className={styles.cardDraftEdit}>
              <div className={styles.pd}>
                <span className={styles.draftCardsHeader}>
                  Customer message:
                </span>
                <div className={styles.pdTop}>
                  <TextField
                    placeholder="Customer message"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    onBlur={() => {
                      if (cart?.message !== message) {
                        saveInfo();
                      }
                    }}
                    fullWidth
                    multiline
                  />
                </div>
              </div>
            </div>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};

export default DraftEdit;
