import { FileResourceType } from "types/FileResourceTypes";
import { ModelType } from "types/ModelTypes";
import { SelectedThreadsInfoType } from "types/RenderTypes";
import { UserType } from "types/users/UserTypes";
import {
  CustomFinishType,
  CustomMaterialType,
  StandardMaterialType,
} from "./ProductOptionsType";
import { AuditableType } from "types/Common";

export enum ProductTypeEnum {
  FEE = "FEE",
  BASE = "BASE",
  OTHER = "OTHER",
  CNC = "CNC",
  PRINT3D = "PRINT3D",
  SHEET = "SHEET",
  CUSTOM = "CUSTOM",
}

export enum ProductToleranceEnum {
  MEDIUM = 0,
  FINE = 1,
}

export enum ProductTypeSurfaceEnum {
  STANDARD = 0,
  FINE = 1,
}

export type ProductVMType = {
  id: number;
  created: string;
  modified: string;
  name: string;
  productType: ProductTypeEnum;
  authorId: number;
  author: UserType;
  priceExpress: number;
  priceStandard: number;
  priceEconomic: number;
  hidePriceFlag: boolean;
  hidePriceFlagQuantity: boolean;
  hidePriceFlagAmount: boolean;
  weight: number;
  custom: boolean;
  details?:
    | CNCProductDetailsType
    | Print3DProductDetailsType
    | SheetProductDetailsType
    | OtherProductDetailsType;
};

export type ManufacturedProductDetailsType = {
  id: number;
  created: string;
  modified: string;
  productId: number;
  modelId: number;
  model: ModelType;
  quantity: number;
  comment: string | null;
  threads: SelectedThreadsInfoType[];
  material?: string; // Deprecated only here for backward compatibility
  standardMaterial?: AuditableType & StandardMaterialType;
  customMaterial?: AuditableType & CustomMaterialType;
  finish?: string[];
  customFinish?: AuditableType & CustomFinishType;
};

export type OtherProductDetailsType = {
  productId: number;
  description: string | null;
  fileResources: FileResourceType[];
};
export type Print3DProductDetailsType = ManufacturedProductDetailsType;

export type CNCProductDetailsType = ManufacturedProductDetailsType & {
  blueprint: string | null;
  tolerance: ProductToleranceEnum;
  surfaceQuality: ProductTypeSurfaceEnum;
  materialCertificate: boolean;
};

export type SheetProductDetailsType = ManufacturedProductDetailsType & {
  blueprint: string | null;
  materialCertificate: boolean;
};

export type EstimateProductType = {
  product: ProductVMType;
  leadTimeDays: {
    EXPRESS: number;
    STANDARD: number;
    ECONOMIC: number;
  };
};

export type CreateCNCProductType = {
  modelId: number;
  quantity: number;
  material: string;
  finish: string[];
  blueprint: string | null;
  comment: string | null;
  threads: SelectedThreadsInfoType[];
  productType: ProductTypeEnum;
  tolerance?: ProductToleranceEnum;
  surfaceQuality?: ProductTypeSurfaceEnum;
  materialCertificate?: boolean;
};

export type UpdateCNCProductType = {
  quantity?: number;
  blueprint?: string | null;
  comment?: string | null;
  threads?: SelectedThreadsInfoType[];
  material?: string;
  customMaterial?: CustomMaterialType;
  finish?: string[];
  customFinish?: CustomFinishType;
  custom?: boolean;
  tolerance?: ProductToleranceEnum;
  surfaceQuality?: ProductTypeSurfaceEnum;
  materialCertificate?: boolean;
  modelId?: number;
};

export type CreatePrint3DProductType = {
  modelId: number;
  quantity: number;
  material: string;
  finish: string[];
  comment: string | null;
  threads: SelectedThreadsInfoType[];
  productType: ProductTypeEnum;
};

export type UpdatePrint3DProductType = {
  quantity?: number;
  comment?: string | null;
  threads?: SelectedThreadsInfoType[];
  material?: string;
  customMaterial?: CustomMaterialType;
  finish?: string[];
  customFinish?: CustomFinishType;
  custom?: boolean;
  modelId?: number;
};

export type CreateSheetProductType = {
  modelId: number;
  quantity: number;
  material: string;
  finish: string[];
  blueprint: string | null;
  comment: string | null;
  threads: SelectedThreadsInfoType[];
  productType: ProductTypeEnum;
  materialCertificate?: boolean;
};

export type UpdateSheetProductType = {
  quantity?: number;
  blueprint?: string | null;
  comment?: string | null;
  threads?: SelectedThreadsInfoType[];
  material?: string;
  customMaterial?: CustomMaterialType;
  finish?: string[];
  customFinish?: CustomFinishType;
  custom?: boolean;
  modelId?: number;
  materialCertificate?: boolean;
};

export type CreateOtherProductType = {
  name: string;
  price: number;
  weight: number;
  description: string;
  fileResources: number[];
  productType: ProductTypeEnum;
};

export type UpdateOtherProductType = {
  name?: string;
  price?: number;
  weight?: number;
  description?: string;
  fileResources?: number[];
};

export type ChangeProductType = {
  productType: ProductTypeEnum;
};
