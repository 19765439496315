import { primaryColor } from "assets/colors";
import React, { FunctionComponent, MouseEventHandler, useState } from "react";
import styles from "./initialsCircle.module.css";

type PropsType = {
  initials: string;
  color?: string;
  onClick?: MouseEventHandler<HTMLDivElement>;
  machineStyle?: boolean;
};

const InitialsCircle: FunctionComponent<PropsType> = ({
  initials,
  color = "#fff",
  onClick,
  machineStyle,
}) => {
  return (
    <div
      className={
        machineStyle ? `${styles.initials__machine}` : `${styles.initials}`
      }
      onClick={onClick}
    >
      <div className={`${styles.initails__typography}`}>{initials}</div>
    </div>
  );
};

export default InitialsCircle;
