import {
  ProductionListType,
  ProductionVMType,
  UpdateProductionType,
  UpdateProductionTaskType,
  ProductionTaskListType,
  ProductionsSummaryType,
  CreateProductionTaskType,
  CreateProductionType,
} from "types/ProductionType";
import { FetchError } from "util/exceptions";
import { API_URL } from "./common";

export const apiGetProduction = (
  token: string,
  conf_id: number
): Promise<ProductionVMType> => {
  return fetch(`${API_URL}/v1/ecommerce/productions/${conf_id}`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    if (res.ok) return res.json();
    else throw new FetchError(res.statusText, res.status);
  });
};

export const apiGetProductionsSummary = (
  token: string
): Promise<ProductionsSummaryType> => {
  return fetch(`${API_URL}/v1/ecommerce/productions/summary`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    if (res.ok) return res.json();
    else throw new FetchError(res.statusText, res.status);
  });
};

export const apiCreateProduction = (
  token: string,
  body: CreateProductionType
): Promise<ProductionVMType> => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(body),
  };
  return fetch(`${API_URL}/v1/ecommerce/productions`, requestOptions).then(
    (res) => {
      if (res.ok) {
        return res.json();
      }
    }
  );
};

export const apiUpdateProduction = (
  token: string,
  id: number,
  body: UpdateProductionType
): Promise<ProductionVMType> => {
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(body),
  };
  return fetch(
    `${API_URL}/v1/ecommerce/productions/${id}`,
    requestOptions
  ).then((res) => {
    if (res.ok) {
      return res.json();
    }
  });
};

export const apiCreateProductionTask = (
  token: string,
  body: CreateProductionTaskType
): Promise<ProductionVMType> => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(body),
  };
  return fetch(
    `${API_URL}/v1/ecommerce/productions/tasks`,
    requestOptions
  ).then((res) => {
    if (res.ok) {
      return res.json();
    }
  });
};

export const apiUpdateProductionTask = (
  token: string,
  id: number,
  body: UpdateProductionTaskType
) => {
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(body),
  };
  console.log("Update");
  return fetch(
    `${API_URL}/v1/ecommerce/productions/tasks/${id}`,
    requestOptions
  ).then((res) => {
    if (res.ok) {
      return res.json();
    }
  });
};

export const apiDeleteProductionTask = (
  token: string,
  id: number
): Promise<void> => {
  return fetch(`${API_URL}/v1/ecommerce/productions/tasks/${id}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    if (!res.ok) throw new FetchError(res.statusText, res.status);
  });
};

export const apiGetProductions = (
  token: string,
  page: number,
  pageSize: number,
  filter: string,
  sortKey: "order_id" | "deadline_date" | "priority" = "order_id",
  sortDirection: "asc" | "desc" = "desc",
  search?: string,
  nextTask?: string,
  // "cam"
  taskStates?: string,
  taskFilter?: string
  // "cam=ACTIVE"
): Promise<ProductionListType> => {
  const url = `${API_URL}/v1/ecommerce/productions` +
            `?page=${page}` +
            `&pageSize=${pageSize}` +
            `&sortKey=${sortKey}` +
            `&sortDirection=${sortDirection}` +
            `${search ? `&search=${search}` : ""}` +
            `${nextTask ? `&nextTask=${nextTask}` : ""}` +
            `${filter ? `&${filter}` : ""}` +
            `${taskStates ? `&taskStates=${taskStates}` : ""}` +
            `${taskFilter ? `&taskFilter=${taskFilter}` : ""}`;
  console.log(url);
  return fetch(url, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    if (res.ok) return res.json();
    else throw new FetchError(res.statusText, res.status);
  });
};

export const apiGetProductionTasks = (
  token: string,
  page: number,
  pageSize: number,
  type: string,
  userId?: number,
  machineId?: number
): Promise<ProductionTaskListType> => {
  return fetch(
    `${API_URL}/v1/ecommerce/productions/tasks?page=${page}&pageSize=${pageSize}&name=${type}${
      userId ? "&userId=" + userId : ""
    }${machineId ? "&machineId=" + machineId : ""}`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  ).then((res) => {
    if (res.ok) return res.json();
    else throw new FetchError(res.statusText, res.status);
  });
};

export const apiGetProductionDatasheet = (
  token: string,
  id: number,
  productId: number
): Promise<void> => {
  return fetch(`${API_URL}/v1/ecommerce/productions/${id}/datasheet`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => {
      if (res.ok) return res.blob();
      else throw new FetchError(res.statusText, res.status);
    })
    .then((blob) => {
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `Setup Sheet #${productId}.xlsx`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    });
};
