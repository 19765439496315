import { FunctionComponent, useContext, useEffect, useState } from "react";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import AppStateContext from "../../contexts/AppStateContext";
import { apiGetProduct, apiUpdateProduct } from "util/network/Products";
import { Badge, IconButton, SvgIcon, TextField, Tooltip } from "@mui/material";
import { CreateRequisitionItemType } from "types/RequisitionTypes";
import {
  OtherProductDetailsType,
  ProductTypeEnum,
  ProductVMType,
  UpdateOtherProductType,
} from "types/products/ProductCommandsType";
import Modal from "components/Modals/Modal";
import FileResourceList from "components/FileResourceList";
import { FileResourceType } from "types/FileResourceTypes";
import { Delete, FileUpload } from "@mui/icons-material";

type PropsType = {
  item: CreateRequisitionItemType;
  deleteItem: (item: CreateRequisitionItemType) => void;
  changeItemPrice: Function;
  priceIn: number | undefined;
  disabled?: boolean;
};

// Requisition Order Item Row
const CustomReqItemRow: FunctionComponent<PropsType> = ({
  item,
  deleteItem,
  changeItemPrice,
  priceIn,
  disabled,
}) => {
  const { token } = useContext(AppStateContext);
  const [product, _setProduct] = useState<ProductVMType>();
  const [details, setDetails] = useState<OtherProductDetailsType>();
  const [priceText, setPriceText] = useState<string>("");
  const [showModal, setShowModal] = useState(false);
  const [fileResources, setFileResources] = useState<FileResourceType[]>([]);
  const [name, setName] = useState<string>("");
  const [description, setDescription] = useState<string>("");

  const handleShowModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const setProduct = (product: ProductVMType) => {
    if (product.productType === ProductTypeEnum.OTHER) {
      _setProduct(product);
      const data = product.details as OtherProductDetailsType;
      setDetails(data);
      setFileResources(data.fileResources);
      setPriceText(`${priceIn ?? ""}`);
      setName(product.name);
      setDescription(data.description ?? "");
    }
  };

  //Inital load
  useEffect(() => {
    if (item.productId) {
      apiGetProduct(token, item.productId).then((res) => {
        setProduct(res);
      });
    }
  }, [item.productId, token]);

  // Update price
  const handlePrice = (price: string) => {
    // Relace comma with dot
    if (price === "") {
      return;
    }
    price = price.replace(",", ".");
    console.log(price);
    let priceFloat = parseFloat(price);
    console.log(priceFloat);
    if (isNaN(priceFloat)) {
      priceFloat = 0;
    }
    setPriceText(`${priceFloat}`);
    const reqItem: CreateRequisitionItemType = {
      productId: item.productId,
      price: priceFloat,
    };
    changeItemPrice(reqItem);
  };
  const updateProduct = (changes: UpdateOtherProductType) => {
    return apiUpdateProduct(token, product!.id, changes).then((res) => {
      if (res) {
        setProduct(res);
      }
    });
  };

  return (
    <TableRow hover key={item.productId} selected={true}>
      {disabled ? null : (
        <TableCell padding="checkbox">
          <IconButton onClick={() => deleteItem(item)}>
            <Delete />
          </IconButton>
        </TableCell>
      )}

      <TableCell align={"center"} colSpan={1}>
        <TextField
          label="Name"
          value={name}
          variant="standard"
          onChange={(e) => setName(e.target.value)}
          onBlur={(e) => {
            if (product) {
              updateProduct({ name: e.target.value });
            }
          }}
        ></TextField>
      </TableCell>
      <TableCell align={"center"} colSpan={1}></TableCell>
      <TableCell align={"center"} colSpan={1}>
        <TextField
          label="Description"
          variant="standard"
          fullWidth
          multiline
          value={description ? description : ""}
          onChange={(e) => setDescription(e.target.value)}
          onBlur={(e) => {
            if (product) {
              updateProduct({ description: e.target.value });
            }
          }}
        ></TextField>
      </TableCell>
      <TableCell colSpan={1} align="center">
        <Tooltip title={<h3>UPLOAD FILES</h3>}>
          <IconButton onClick={handleShowModal}>
            <Badge badgeContent={fileResources.length} color="primary">
              <SvgIcon>
                <FileUpload />
              </SvgIcon>
            </Badge>
          </IconButton>
        </Tooltip>
        <Modal isOpen={showModal} onClose={handleCloseModal}>
          <div
            style={{
              height: "100%",
              overflow: "auto",
            }}
          >
            <FileResourceList
              resources={fileResources}
              allowEdit
              onChange={(res) => {
                return updateProduct({
                  fileResources: res.map((r) => r.id),
                }).then(() => {
                  return true;
                });
              }}
            />
          </div>
        </Modal>
      </TableCell>
      {/* <TableCell align={"center"}>
        <FormControl
          variant="standard"
          sx={{ m: 1, minWidth: 80 }}
          size="medium"
        >
          <Input
            endAdornment={<InputAdornment position="end">kr</InputAdornment>}
            type="number"
            placeholder="Suggested price"
            value={priceText}
            onBlur={(e) => handlePrice(e.target.value)}
            onChange={(e) => setPriceText(e.target.value)}
            onClick={(e) => e.stopPropagation()}
          />
        </FormControl>
      </TableCell> */}
    </TableRow>
  );
};

export default CustomReqItemRow;
