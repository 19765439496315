import { CartItemType } from "./CartTypes";
import { OrderItemType } from "./OrderTypes";
import { PartnerType } from "./partners/PartnerTypes";
import { ProductVMType } from "./products/ProductCommandsType";
import { UserType } from "./users/UserTypes";

export enum RequisitionTypeEnum {
  CNC = "CNC",
  PRINT3D = "PRINT3D",
  SHEET = "SHEET",
  FINISH = "FINISH",
  CUSTOM = "CUSTOM",
}

export enum RequisitionStatusEnum {
  REQUEST = "REQUEST",
  RESPONSE = "RESPONSE",
  PROCESSING = "PROCESSING",
  PICKUP = "PICKUP",
  RECEIVED = "RECEIVED",
  PAUSED = "PAUSED",
  CANCELLED = "CANCELLED",
}

export type CreateRequisitionType = {
  orderId?: number;
  quoteId?: number;
  partnerId: number;
  type: string;
  deadline?: string;
  deliveryMethod: string;
  status?: RequisitionStatusEnum;
  message: string;
  items: CreateRequisitionItemType[];
  price?: number;
  title?: string;
};

export type RenderRequisitionType = {
  id?: number;
  orderId?: number;
  quoteId?: number;
  partnerId: number;
  type: string;
  deadline?: string;
  deliveryMethod: string;
  status?: RequisitionStatusEnum;
  message: string;
  items: CreateRequisitionItemType[];
  price?: number;
  title?: string;
};

export type CreateRequisitionItemType = {
  productId: number;
  orderItemId?: number | null;
  quoteItemId?: number | null;
  price?: number;
};

export type UpdateRequisitionType = {
  state: RequisitionStatusEnum;
  deadline?: string;
  deliveryMethod?: string;
  message?: string;
  items?: (UpdateRequisitionItemType | CreateRequisitionItemType)[];
};

export type UpdateRequisitionItemType = CreateRequisitionItemType & {
  id: number;
};

export type RequisitionType = {
  created_by: string;
  created: string;
  id: number;
  partnerId: number;
  partner: PartnerType;
  title?: string;
  type: RequisitionTypeEnum;
  deliveryDate: string;
  deliveryMethod: string;
  state: RequisitionStatusEnum;
  orderId?: number;
  quoteId?: number;
  customer: UserType;
  message: string;
  items: RequisitionItemType[];
};

export type RequisitionItemType = {
  id: number;
  reqId: number;
  product: ProductVMType;
  price: number;
  orderItemId: number | null;
  orderItem: OrderItemType | null;
  quoteItemId: number | null;
  quoteItem: CartItemType | null;
};

export type RequisitionListType = {
  items: RequisitionType[];
  page: number;
  pageSize: number;
  total: number;
};
