import { FunctionComponent, useContext, useState } from "react";
import { CartItemType } from "types/CartTypes";
import {
  OtherProductDetailsType,
  UpdateOtherProductType,
} from "types/products/ProductCommandsType";
import FileResourceList from "components/FileResourceList";
import Modal from "components/Modals/Modal";
import { apiUpdateProduct } from "util/network/Products";
import AppStateContext from "contexts/AppStateContext";
import { FileResourceType } from "types/FileResourceTypes";
import {
  Badge,
  IconButton,
  SvgIcon,
  TableCell,
  TableRow,
  Tooltip,
} from "@mui/material";
import { Delete, Edit, FileUpload } from "@mui/icons-material";
import EditOtherProduct from "components/EditOtherProductModal/EditOtherProduct";
import React from "react";
import { apiUpdateCartItem } from "util/network/Carts";
import { useToast } from "contexts/ToastContext";

type PropsType = {
  cartId: number;
  item: CartItemType;
  onChange: (item: CartItemType) => void;
  deleteItem: (item: CartItemType) => Promise<boolean>;
  disabled?: boolean;
};

const OtherItemTableRow: FunctionComponent<PropsType> = React.memo(
  ({ cartId, item, onChange, deleteItem, disabled = false }) => {
    const { token } = useContext(AppStateContext);
    const { id, product } = item;
    const details = product.details as OtherProductDetailsType;

    const [name, setName] = useState<string>(product.name);
    const [description, setDescription] = useState<string | null>(
      details.description
    );
    const [quantity, setQuantity] = useState<number>(item.quantity);
    const [price, setPrice] = useState<number>(item.pricePerUnit);
    const [total, setTotal] = useState<number>(item.total);
    const [fileResources, setFileResources] = useState<FileResourceType[]>(
      details.fileResources
    );
    const [weight, setWeight] = useState<number>(product.weight);

    const { addToast } = useToast();

    const [showModal, setShowModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);

    const formatFileSize = (bytes: number) => {
      if (bytes === 0) return "0 B";
      const k = 1024;
      const sizes = ["B", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
      const i = Math.floor(Math.log(bytes) / Math.log(k));
      const size = sizes[i];
      return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + " " + size;
    };

    const truncateString = (str: string, num: number) => {
      if (str.length <= num) {
        return str;
      }
      return str.slice(0, num) + "...";
    };

    const handleShowModal = () => {
      setShowModal(true);
    };

    const handleCloseModal = () => {
      setShowModal(false);
    };

    const handleEditShowModal = () => {
      setShowEditModal(true);
    };

    const handleEditCloseModal = () => {
      setShowEditModal(false);
    };

    const handleFileResourceChange = (resources: FileResourceType[]) => {
      console.log("handleFileResourceChange", resources);
      const command: UpdateOtherProductType = {
        fileResources: resources.map((x) => x.id),
      };
      setFileResources(resources);
      return apiUpdateProduct(token, product.id, command)
        .then((res) => {
          return apiUpdateCartItem(token, cartId, item.id, {
            quantity: quantity,
          })
            .then((res) => {
              onChange(item);
              return true;
            })
            .catch((err) => {
              addToast({
                type: "error",
                message: "Could not update item correctly in cart" + err,
                keep: true,
              });
              return false;
            });
        })
        .catch((err) => {
          addToast({
            type: "error",
            message: "Could not update product correctly" + err,
            keep: true,
          });
          return false;
        });
    };

    const handleRowChanges = (update: UpdateOtherProductType) => {
      return apiUpdateProduct(token, product.id, update)
        .then((res) => {
          setName(res.name);
          const deets = res.details as OtherProductDetailsType;
          setDescription(deets.description ?? null);
          setPrice(res.priceStandard);
          setTotal(res.priceStandard * quantity);
          setWeight(res.weight);
          return true;
        })
        .then((res) => {
          return apiUpdateCartItem(token, cartId, item.id, {
            quantity: quantity,
          })
            .then((res) => {
              setQuantity(res.quantity);
              onChange(item);
              setShowEditModal(false);
              return true;
            })
            .catch((err) => {
              addToast({
                type: "error",
                message: "Could not update item correctly in cart" + err,
                keep: true,
              });
              return false;
            });
        })
        .catch((err) => {
          addToast({
            type: "error",
            message: "Could not update product correctly" + err,
            keep: true,
          });
          return false;
        });
    };

    let initialDescription = "";
    if (
      product.details &&
      "description" in product.details &&
      product.details.description !== null
    ) {
      initialDescription = product.details.description;
    }

    return (
      <TableRow>
        <TableCell colSpan={2}>{truncateString(name, 50)}</TableCell>
        <TableCell colSpan={3}>
          {description ? truncateString(description, 100) : ""}
        </TableCell>
        <TableCell colSpan={3}>{total.toFixed(2)} kr.</TableCell>
        <TableCell colSpan={1} align="left">
          <div style={{ display: "flex", flexDirection: "row" }}>
            <Tooltip title={<h3>UPLOAD FILES</h3>}>
              <IconButton onClick={handleShowModal}>
                <Badge badgeContent={fileResources.length} color="primary">
                  <SvgIcon>
                    <FileUpload />
                  </SvgIcon>
                </Badge>
              </IconButton>
            </Tooltip>
            <Tooltip title={<h3>DELETE</h3>} placement="bottom">
              <IconButton
                aria-label={"Delete"}
                style={{ color: "var(--mainRed)" }}
                onClick={() => deleteItem(item)}
                // disabled={disabled}
              >
                <Delete fontSize={"medium"} />
              </IconButton>
            </Tooltip>
            <Tooltip title={<h3>EDIT</h3>} placement="bottom">
              <IconButton
                aria-label={"Edit"}
                color={"primary"}
                onClick={handleEditShowModal}
                // disabled={disabled}
              >
                <Edit fontSize={"medium"} />
              </IconButton>
            </Tooltip>
          </div>

          <Modal isOpen={showModal} onClose={handleCloseModal}>
            <div
              style={{
                height: "100%",
                overflow: "auto",
              }}
            >
              <FileResourceList
                resources={fileResources}
                allowEdit={!disabled}
                onChange={handleFileResourceChange}
              />
            </div>
          </Modal>
          <Modal
            key={product.id}
            isOpen={showEditModal}
            onClose={handleEditCloseModal}
          >
            <div
              style={{
                height: "100%",
              }}
            >
              <EditOtherProduct
                onSubmit={handleRowChanges}
                onCancel={() => handleEditCloseModal()}
                product={product}
                initialName={product.name}
                initialWeight={weight.toString()}
                initialPrice={price.toString()}
                initialDescription={initialDescription}
                setIsModalOpen={handleEditShowModal}
                isModalOpen={showEditModal}
              />
            </div>
          </Modal>
        </TableCell>
      </TableRow>
    );
  }
);

export default OtherItemTableRow;
