// Author: Erik s195397
import {
  TableRow,
  TableCell,
  Typography,
  Tooltip,
  IconButton,
  Badge,
  SvgIcon,
} from "@mui/material";
import { FunctionComponent } from "react";
import { RequisitionItemType } from "types/RequisitionTypes";
import AppStateContext from "contexts/AppStateContext";
import { useContext, useEffect, useState } from "react";
import { FileUpload } from "@mui/icons-material";
import { OtherProductDetailsType } from "types/products/ProductCommandsType";
import FileResourceList from "components/FileResourceList";
import Modal from "components/Modals/Modal";

type PropsType = {
  item: RequisitionItemType;
};

// Requisition item row
const CustomRequisitionItemRow: FunctionComponent<PropsType> = ({ item }) => {
  const { token, strings, setShowThreeDModal, setProductId } =
    useContext(AppStateContext);
  const [pdfExists, setPdfExists] = useState<boolean>();
  const [colorStepExist, setColorStepExist] = useState<boolean>();

  const [details, setDetails] = useState<OtherProductDetailsType>();

  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    const dat = item.product.details as OtherProductDetailsType;
    setDetails(dat);
  }, [item.product]);

  return (
    <TableRow key={item.id}>
      <TableCell align={"center"} colSpan={1}>
        <Typography>{item.product.name}</Typography>
      </TableCell>
      <TableCell align={"center"} colSpan={2} sx={{ maxWidth: "20em" }}>
        <Typography>{details?.description}</Typography>
      </TableCell>
      <TableCell colSpan={1} align="center">
        <Tooltip title={<h3>FILES</h3>}>
          <IconButton onClick={() => setShowModal(true)}>
            <Badge badgeContent={details?.fileResources.length} color="primary">
              <SvgIcon>
                <FileUpload />
              </SvgIcon>
            </Badge>
          </IconButton>
        </Tooltip>
        <Modal isOpen={showModal} onClose={() => setShowModal(false)}>
          <div
            style={{
              height: "100%",
              overflow: "auto",
            }}
          >
            <FileResourceList resources={details?.fileResources ?? []} />
          </div>
        </Modal>
      </TableCell>
      {/* <TableCell align="center">{item.price + " kr"}</TableCell> */}
    </TableRow>
  );
};

export default CustomRequisitionItemRow;
