import { UserType } from "./users/UserTypes";
import { SelectedThreadsInfoType } from "./RenderTypes";
import { ProductVMType } from "./products/ProductCommandsType";
import { MachineType } from "./MachineType";
import { OrderType } from "./OrderTypes";

export enum ProductionStatusEnum {
  CANCELLED = -1,
  DONE = 1,
  TODO = 0,
}

export enum ProductionTaskStateEnum {
  TODO = "TODO",
  ACTIVE = "ACTIVE",
  DONE = "DONE",
}

export enum ProductionTaskEnum {
  CAM = "cam",
  REVIEW = "review",
  MATERIALCUT = "production",
  MILLING = "mill",
  TURNING = "turning",
  QUALITYCONTROL = "afterwork",
  MAUNALFINISH = "manualfinish",
  SHIPPING = "shipping",
  OUTSOURCE = "outsource",
  ANODIZE = "anodize",
  ELECTROPLISH = "electroplish",
  BEADBLAST = "beadblast",
  DONE = "done",
}

export type CreateProductionType = {
  productId: number;
  customerId?: number;
  deadline?: string;
  orderId?: number;
  outsourced?: boolean;
};

export type UpdateProductionType = {
  customerId?: number;
  deadline?: string;
  orderId?: number;
  priority?: number;
  outsourced?: boolean;
  status?: ProductionStatusEnum;
  tasks?: UpdateProductionTaskType[];
};

export type CreateProductionTaskType = {
  name: string;
  productionId: number;
  description?: string;
  priority?: number;
  deadline?: string;
  leadTime?: number;
  workTime?: number;
  progressMax?: number;
  users?: number[];
  machines?: number[];
  state?: ProductionTaskStateEnum;
  allowUsers?: boolean;
  allowMachines?: boolean;
  allowWorkTime?: boolean;
  allowLeadTime?: boolean;
  allowProgress?: boolean;
};

export type UpdateProductionTaskType = {
  id: number;
  name?: string;
  description?: string;
  priority?: number;
  deadline?: string;
  leadTime?: number;
  workTime?: number;
  progress?: number;
  progressMax?: number;
  users?: number[];
  machines?: number[];
  state?: ProductionTaskStateEnum;
};

// TODO: remove
export type ProductionTaskDetailedType = {
  id: number;
  created: string;
  modified: string;
  name: string;
  priority: number;
  productionId: number;
  users: UserType[];
  machines: MachineType[];
  state: ProductionTaskStateEnum;
  product: ProductVMType;
  customer: UserType;
  orderId: number;
};

export type ProductionTaskType = {
  id: string;
  created: string;
  modified: string;
  created_by: string;
  modified_by: string;
  created_by_id: number;
  modified_by_id: number;
  productionId: number;
  priority: number;
  name: string;
  description: string;
  state: ProductionTaskStateEnum;
  progress: number;
  progressMax: number;
  workTime: number;
  leadTime: number;
  deadline: string;
  allowUsers: boolean;
  allowMachines: boolean;
  allowWorkTime: boolean;
  allowLeadTime: boolean;
  allowProgress: boolean;
  prevTaskId: number;
  nextTaskId: number;
  users: {
    id: number;
    firstName: string;
    lastName: string;
  }[];
  machines: {
    id: number;
    name: string;
    label: string;
  }[];
};

export type ProductionVMType = {
  id: number;
  created: string;
  modified: string;
  created_by: string;
  modified_by: string;
  created_by_id: number;
  modified_by_id: number;
  productId: number;
  product: ProductVMType;
  orderId: number;
  customerId: number;
  deadlineDate: string;
  priority: number;
  status: ProductionStatusEnum;
  outsourced: boolean;
  tasks: ProductionTaskType[];
  type: string;
  orderDate: string;
  orderCompanyName: string;
  orderMessage: string;
};

export type SimpleProductionVMType = {
  id: number;
  created: string;
  modified: string;
  created_by_id: number;
  modified_by_id: number;
  productId: number;
  orderId: number;
  customerId: number;
  deadlineDate: string;
  priority: number;
  status: ProductionStatusEnum;
  outsourced: boolean;
  tasks: number[];
  type: string;
  orderCompanyName: string;
  orderDate: string;
};

export type ProductionListType = {
  items: ProductionVMType[];
  page: number;
  pageSize: number;
  total: number;
};

export type ProductionTaskListType = {
  items: ProductionTaskDetailedType[];
  page: number;
  pageSize: number;
  total: number;
};

export type ProductionsSummaryType = {
  reviewReady: number;
  camReady: number;
  productionReady: number;
  afterworkReady: number;
  shippingReady: number;
  customReady: number;
  totalActive: number;
  machineInfo: {
    [key: string]: {
      items: number;
      label: string;
      time: number;
    };
  };
};
