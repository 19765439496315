const columns = [
  {
    id: "checkbox",
    label: "",
    align: "center",
  },
  {
    id: "project",
    label: "Project",
    align: "center",
  },
  {
    id: "quantity",
    label: "Qty",
    align: "center",
  },

  {
    id: "modelName",
    label: "Name",
    align: "center",
  },
  {
    id: "details",
    label: "Details",
    align: "center",
  },
  // {
  //   id: "price",
  //   label: "Price",
  //   align: "center",
  // }
];

export default columns;
